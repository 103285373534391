<template>
  <div class="bg-white overflow-y-auto h-full p-2">
    <nav class="flex flex-col" aria-label="Key dates">
      <dt class="text-sm font-medium text-gray-500">
        {{ editingState ? "Current Milestone" : "Milestones" }}
      </dt>
      <ol v-if="loaded" role="list" class="mt-2 space-y-4">
        <ContentMilestoneDropdown
          v-if="editingState"
          :decorating-data-field="dataField"
          :existing-state="state"
          :fetch-request-key="fetchRequestKey"
          :field-name-modifier="stateName"
          @open-sourced="unlocked"
          @unlocked="unlocked"
          @completed="completed"
          @cancel="cancel"
        />
        <template v-else>
          <ContentMilestone
            v-for="optionObj in filteredMilestones"
            :key="optionObj.value"
            :decorating-data-field="dataField"
            :state-data-field="state"
            :milestone-object="optionObj"
            :all-milestones="filteredMilestones"
            :field-name-modifier="stateName"
            @open-sourced="unlocked"
            @unlocked="unlocked"
            @completed="updateState"
            @suggest-state-change="suggestStateChange"
          />
        </template>
      </ol>
    </nav>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import milestones from "@/assets/contentMilestones";
import _ from "lodash";
import ContentMilestone from "@/components/crowdsourcing/ContentMilestone.vue";
import ContentMilestoneDropdown from "@/components/crowdsourcing/ContentMilestoneDropdown.vue";

const props = defineProps(["dataField", "context"]);
const emit = defineEmits(["unlocked"]);

const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder } = storeToRefs(spaceUsageBuilderStore);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);

const loaded = ref(false);
const state = ref(null);
const editingState = ref(false);
const selectedValue = ref(null);
const contentType = computed(() => props.dataField.fieldContentType);
const contentId = computed(() => props.dataField.fieldContent.id);
const combinedId = computed(() => `${contentType.value}${contentId.value}`);
const dropdownOptions = computed(() => {
  let matchingOptions = null;
  if (props.dataField.fieldContentType === "PropertyRight") {
    matchingOptions = _.get(
      milestones,
      `PropertyRight[${props.dataField.fieldContent.type}]`,
      [],
    );
  } else if (props.context === "leaseOccupancy") {
    matchingOptions = _.get(milestones, "SpaceUsageOccupancy", []);
  } else {
    matchingOptions = _.get(milestones, props.dataField.fieldContentType, []);
  }

  return _.sortBy(matchingOptions, ["order"]);
});
const filteredMilestones = computed(() => {
  if (
    state.value?.fieldValue &&
    _.includes(["closed", "construction_completed"], state.value.fieldValue)
  ) {
    return dropdownOptions.value.filter(
      (optionObj) => optionObj.value !== "withdrawn",
    );
  } else if (
    state.value?.fieldValue &&
    _.includes(["fulfilled"], state.value.fieldValue)
  ) {
    return dropdownOptions.value.filter(
      (optionObj) => optionObj.value !== "ceased",
    );
  } else {
    return dropdownOptions.value;
  }
});

watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);
  console.log("content timing licenses channel watcher", data);

  if (data.dataFieldIds) {
    fetchState({ override: true });
    dropDateFetchKeys();
  }
});
watch(combinedId, () => {
  fetchState();
});
watch(
  () => props.context,
  () => {
    fetchState();
  },
);
watch(asOfDate, (date, oldDate) => {
  if (date !== oldDate && props.context === "detailsTab") {
    fetchState();
  }
});
onMounted(() => fetchState());

const stateName = computed(() => {
  if (props.context === "leaseOccupancy") {
    return "occupancy_state";
  } else if (props.dataField.fieldContentType === "SpaceUsage") {
    return "contract_state";
  } else {
    return "state";
  }
});
const fetchRequestKey = computed(() => {
  return `crowdsourced_data_fields_${contentType.value}_${contentId.value}?field_name=${stateName.value}`;
});

function dropDateFetchKeys() {
  filteredMilestones.value.forEach(({ value }) => {
    const impliedDateName = `${value}_date`;
    const fetchKey = `crowdsourced_data_fields_${contentType.value}_${contentId.value}?field_name=${impliedDateName}`;

    dealBuilderStore.dropFetchRequest(fetchKey);
    spaceUsageBuilderStore.dropFetchRequest(fetchKey);
  });
}

function unlocked(maybePayload) {
  if (props.dataField?.fieldContentType === "Investment") {
    emit("unlocked", maybePayload);
  } else {
    fetchState(maybePayload);
  }
}
function completed(maybePayload) {
  if (maybePayload?.dataField) {
    updateState(maybePayload.dataField);
  }
}
function updateState(maybeState) {
  if (maybeState) {
    if (state.value && state.value.fieldValue !== maybeState?.fieldValue) {
      timeTravelStore.triggerRefetch();
    } else if (maybeState?.decoratingContentType === "Hunt") {
      timeTravelStore.triggerRefetch();
    } else {
      state.value = maybeState;
    }
  } else fetchState();
}

function fetchState(maybePayload) {
  loaded.value = false;

  if (
    dealBuilderStore.alreadyFetched(fetchRequestKey.value) &&
    !maybePayload?.override
  ) {
    state.value = _.head(
      dealBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey.value),
    );
    loaded.value = true;
  } else if (
    spaceUsageBuilderStore.alreadyFetched(fetchRequestKey.value) &&
    !maybePayload?.override
  ) {
    state.value = _.head(
      spaceUsageBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey.value),
    );
    loaded.value = true;
  } else {
    api
      .get(
        `crowdsourced_data_fields/${contentType.value}/${contentId.value}?field_name=${stateName.value}`,
      )
      .then((json) => {
        if (dealBuilder.value && json.data) {
          dealBuilderStore.interceptablePatch(
            [json.data],
            fetchRequestKey.value,
          );
        } else if (spaceUsageBuilder.value && json.data) {
          spaceUsageBuilderStore.interceptablePatch(
            [json.data],
            fetchRequestKey.value,
          );
        }
        state.value = json.data;
        loaded.value = true;
      });
  }
}

function suggestStateChange() {
  setSelectedValue();
  editingState.value = true;
}
function setSelectedValue() {
  if (_.get(state.value, "fieldValue")) {
    selectedValue.value = _.find(dropdownOptions.value, {
      value: state.value.fieldValue,
    }).value;
  } else {
    selectedValue.value = _.head(dropdownOptions.value).value;
  }
}
function cancel() {
  editingState.value = false;
}
</script>
