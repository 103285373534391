<template>
  <div class="overflow-hidden rounded-lg bg-gray-100">
    <div class="p-2 space-y-2">
      <DataField
        v-if="removable"
        :data-field="scheduleDataField"
        class="mt-2 flex items-center"
        text-classes="text-sm font-medium"
        text-styles=""
        @unlocked="emit('unlocked')"
        @open-sourced="emit('open-sourced')"
      />
      <div
        v-else
        v-tooltip="'Remove at the space level'"
        class="inline-flex rounded-full items-center py-0.5 px-2 text-sm font-medium bg-gray-200 text-gray-700"
      >
        {{ scheduleDataField.fieldContent?.name }}
      </div>
      <div v-if="filteredTabs.length > 1" class="">
        <div :class="[workspaceLayout === 'sideBySide' ? 'hidden' : '']">
          <label for="tabs" class="sr-only">Select a tab</label>
          <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
          <select
            v-model="selectedTab"
            id="contribution-data-tabs"
            name="contribution-data-tabs"
            class="block text-sm rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          >
            <option v-for="tab in filteredTabs" :key="tab.name" :value="tab">
              {{ tab.name }}
            </option>
          </select>
        </div>
        <div :class="[workspaceLayout === 'sideBySide' ? '' : 'hidden']">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex" aria-label="Tabs">
              <a
                v-for="tab in filteredTabs"
                @click.prevent="selectedTab = tab"
                :key="tab.name"
                href=""
                :class="[
                  selectedTab.name === tab.name
                    ? 'border-indigo-500 text-indigo-600 font-bold'
                    : 'font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'w-1/2 border-b-2 p-2 text-sm text-center',
                ]"
                :aria-current="
                  selectedTab.name === tab.name ? 'page' : undefined
                "
                >{{ tab.name }}</a
              >
            </nav>
          </div>
        </div>
      </div>
      <div id="cashflow-schedule-field-container" class="flex-grow">
        <KeepAlive>
          <component
            :is="selectedTab.component"
            v-bind="{
              dataField: contentDataField,
              availabilityGroupId,
              scheduleDataField,
              dateFields,
              perAreaSize,
            }"
          />
        </KeepAlive>
      </div>
    </div>
  </div>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import CashflowInputsSummary from "@/components/crowdsourcing/CashflowInputsSummary.vue";
import SteppedCashflowPreviewTable from "@/components/crowdsourcing/SteppedCashflowPreviewTable.vue";
import TenantImprovementAllowanceLinkedLayouts from "@/components/crowdsourcing/TenantImprovementAllowanceLinkedLayouts.vue";
import TimeSeriesCashflowPreviewTable from "@/components/crowdsourcing/TimeSeriesCashflowPreviewTable.vue";
import { computed, markRaw, ref } from "vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import _ from "lodash";

const props = defineProps([
  "contentDataField",
  "availabilityGroupId",
  "scheduleDataField",
  "dateFields",
  "perAreaSize",
  "analyze",
  "removable",
]);
const emit = defineEmits(["unlocked", "open-sourced"]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const schedule = computed(() => _.get(props.scheduleDataField, "fieldContent"));
const missingOutputs = computed(() => {
  const blocks = schedule.value?.buildingBlocks || [];
  return !_.every(blocks, function ({ monthlyOutput }) {
    return !!monthlyOutput;
  });
});

const tabs = [
  {
    name: "Stepping Schedule",
    component: markRaw(SteppedCashflowPreviewTable),
  },
  {
    name: "Inputs Summary",
    component: markRaw(CashflowInputsSummary),
  },
  {
    name: "Linked Layouts",
    component: markRaw(TenantImprovementAllowanceLinkedLayouts),
  },
  {
    name: "Time Series Output",
    component: markRaw(TimeSeriesCashflowPreviewTable),
  },
];
const filteredTabs = computed(() => {
  switch (schedule.value.scheduleType) {
    case "stepping": {
      const baseFiltered = tabs.filter(
        ({ name }) => !_.includes(["Linked Layouts"], name),
      );

      if (props.analyze || missingOutputs.value) {
        return baseFiltered.filter(({ name }) => name !== "Time Series Output");
      } else {
        return baseFiltered;
      }
    }
    case "tenantImprovementAllowance": {
      const typedTabExclusions =
        props.contentDataField?.fieldContent?.space?.recordType === "FloorArea"
          ? ["Stepping Schedule"]
          : ["Stepping Schedule", "Linked Layouts"];
      const baseFiltered = tabs.filter(
        ({ name }) => !_.includes(typedTabExclusions, name),
      );
      return baseFiltered;
    }
    default:
      return tabs;
  }
});
const selectedTab = ref(_.head(filteredTabs.value));
</script>
