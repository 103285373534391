<template>
  <main class="max-w-lg mx-auto p-2">
    <form @submit.prevent>
      <div class="space-y-6">
        <div>
          <label
            for="team_name"
            class="block text-sm font-medium text-gray-700"
          >
            Team Name
          </label>
          <div class="mt-1">
            <input
              v-if="isOwner || !teamId"
              v-focus
              v-model="name"
              type="text"
              name="team_name"
              id="team_name"
              class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-sm border-gray-300 rounded-md"
              data-test="team-name-input"
            />
            <h3
              v-else
              class="px-1 font-semibold text-xl leading-5 text-gray-900"
            >
              {{ name }}
            </h3>
          </div>
        </div>

        <SwitchGroup
          v-if="canSubsidize"
          as="div"
          class="flex items-center justify-between"
        >
          <span class="flex flex-grow flex-col">
            <SwitchLabel
              as="span"
              class="text-sm font-medium text-gray-900"
              passive
              >Pay for member usage</SwitchLabel
            >
            <SwitchDescription as="span" class="text-sm text-gray-500"
              >Use the primary payment method to fund data credits for team
              members.</SwitchDescription
            >
          </span>
          <Switch
            v-model="subsidizing"
            :class="[
              subsidizing ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
            ]"
            data-test="team-subsidizing-toggle"
          >
            <span
              aria-hidden="true"
              :class="[
                subsidizing ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              ]"
            />
          </Switch>
        </SwitchGroup>
        <div
          v-if="canSubsidize && subsidizing && !subscriptionId"
          class="flex justify-end"
        >
          <TeamSubscribeVue
            :team-id="teamId"
            @saved="emit('saved')"
            @payment-method-failed="subsidizing = false"
          />
        </div>

        <div class="space-y-2">
          <div v-if="isOwner || !teamId" class="space-y-1">
            <label
              for="add_team_members"
              class="flex justify-between text-sm font-medium text-gray-700"
            >
              <div class="flex">
                <span>Add Team Members</span>
                <div class="flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    v-tooltip="
                      'Invite existing Tower Hunt users or bring on new users via email.'
                    "
                    class="ml-1 h-5 w-5 text-gray-700"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </label>
            <p id="add_team_members_helper" class="sr-only">
              Search by name or email address
            </p>
            <div class="flex">
              <div class="flex-grow focus-within:z-10">
                <UserTeamAutocomplete
                  :search-teams="false"
                  :selections="_.concat(members, invitations)"
                  :remove-inline="false"
                  input-classes="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  input-key="team-form"
                  :focus="false"
                  :no-self="true"
                  @add-user="addUser"
                />
              </div>
            </div>
          </div>

          <div class="border-b border-gray-200">
            <ul v-if="membersLoaded" class="divide-y divide-gray-200">
              <li v-if="!teamId" class="py-4 flex">
                <div class="flex-shrink-0">
                  <AvatarPhoto
                    :person="currentUser"
                    shape-size="h-10 w-10"
                    text-size="md"
                  />
                </div>
                <div class="ml-3 flex flex-col">
                  <span class="text-sm font-medium text-gray-900">{{
                    currentUser.name
                  }}</span>
                  <span class="text-sm text-gray-500"
                    >Primary owner<template v-if="currentUser.company"
                      >&nbsp;&middot; {{ currentUser.company }}</template
                    ></span
                  >
                </div>
              </li>
              <li
                v-for="(invitation, index) in invitations"
                :key="invitation.token"
                class="relative py-4 flex items-center justify-between"
                :data-test="`team-invitation-listing-${index}`"
              >
                <div class="flex items-center">
                  <div class="flex-shrink-0">
                    <span
                      class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 text-white"
                    >
                      <svg
                        class="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </span>
                  </div>
                  <div class="ml-3 flex flex-col">
                    <span
                      class="text-sm font-medium text-gray-900"
                      :data-test="`team-invitation-email-${index}`"
                      >{{ invitation.email }}</span
                    >
                    <span class="text-sm text-gray-500">Pending</span>
                  </div>
                </div>
                <TeamMemberMenu
                  :invitation="invitation"
                  @refetch-invitations="fetchInvitations"
                />
              </li>
              <li
                v-for="(member, index) in members"
                :key="member.id"
                class="relative py-4 flex items-center justify-between"
                :data-test="`team-member-listing-${index}`"
              >
                <div class="flex items-center">
                  <div class="flex-shrink-0">
                    <AvatarPhoto
                      :person="member"
                      shape-size="h-10 w-10"
                      text-size="md"
                    />
                  </div>
                  <div class="ml-3 flex flex-col">
                    <span
                      class="text-sm font-medium text-gray-900"
                      :data-test="`team-member-name-${index}`"
                      >{{ member.name }}</span
                    >
                    <span
                      class="text-sm text-gray-500"
                      :data-test="`team-member-role-${index}`"
                      ><template v-if="member.teamRole">{{
                        member.teamRole
                      }}</template
                      ><template v-else>Save team to invite</template></span
                    >
                    <TeamMemberBilling
                      v-if="member.memberId"
                      :member="member"
                      :can-edit="isManager"
                      @refetch-members="fetchMembers"
                      @refetch-team="fetchTeam"
                    />
                  </div>
                </div>
                <TeamMemberMenu
                  v-if="member.teamRole"
                  :member="member"
                  :role="role"
                  @refetch-members="fetchMembers"
                  @refetch-team="fetchTeam"
                  @stop-editing="cancel"
                />
                <button
                  v-else
                  @click="removePerson(member)"
                  type="button"
                  class="flex-shrink-0 ml-0.5 mr-2 h-8 w-8 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
                >
                  <span class="sr-only">Remove person</span>
                  <svg
                    class="h-3 w-3"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-width="1.5"
                      d="M1 1l6 6m0-6L1 7"
                    />
                  </svg>
                </button>
              </li>
            </ul>
            <div v-else class="flex justify-center">
              <GridLoader :loading="true" size="6px" color="#D4D4D8" />
            </div>
          </div>
        </div>

        <div class="flex justify-end">
          <button
            @click="cancel"
            type="button"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            data-test="cancel-team-button"
          >
            Cancel
          </button>
          <button
            v-if="teamId && isOwner"
            @click="update"
            type="button"
            :disabled="!complete"
            class="ml-3 inline-flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2"
            :class="buttonStyling"
            data-test="update-team-button"
          >
            Update team
          </button>
          <button
            v-else-if="!teamId"
            @click="save"
            type="button"
            :disabled="!complete"
            class="ml-3 inline-flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2"
            :class="buttonStyling"
            data-test="save-team-button"
          >
            Create team
          </button>
        </div>
      </div>
    </form>
  </main>
</template>

<script setup>
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import TeamMemberMenu from "@/components/users/teams/TeamMemberMenu.vue";
import TeamSubscribeVue from "@/components/users/teams/TeamSubscribeVue.vue";
import api from "@/router/api";
import UserTeamAutocomplete from "@/components/contacts/UserTeamAutocomplete.vue";
import TeamMemberBilling from "@/components/users/teams/TeamMemberBilling.vue";
import { computed, onMounted, ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import {
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import _ from "lodash";

const props = defineProps(["teamId"]);
const emit = defineEmits(["cancel", "saved"]);
const teamLoaded = ref(false);
const invitationsLoaded = ref(false);
const membersLoaded = ref(false);
const name = ref(null);
const role = ref(null);
const canSubsidize = ref(false);
const subsidizing = ref(false);
const subscriptionId = ref(null);
const invitations = ref([]);
const members = ref([]);

const userStore = useUserStore();
const { currentUser, emailUnverified } = storeToRefs(userStore);

const isOwner = computed(() => _.includes(["Primary owner"], role.value));
const isManager = computed(() =>
  _.includes(["Primary owner", "Billing member"], role.value)
);
const complete = computed(() => name.value && _.trim(name.value) !== "");
const buttonStyling = computed(() => {
  if (complete.value) {
    return "inline-flex justify-center border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
  } else {
    return "border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-indigo-500 cursor-not-allowed";
  }
});
const persistPayload = computed(() => {
  if (complete.value) {
    return {
      name: name.value,
      members: members.value.filter((person) => !person.memberId),
      invitations: invitations.value.filter((invitee) => !invitee.token),
    };
  } else {
    return {};
  }
});

watch(subsidizing, (val, oldVal) => {
  const downgrading = oldVal && !val;
  if (props.teamId && downgrading) {
    cancelSubscription();
  }
});

onMounted(() => {
  if (props.teamId) {
    fetchTeam();
    fetchInvitations();
    fetchMembers();
  } else {
    teamLoaded.value = invitationsLoaded.value = membersLoaded.value = true;
  }
});

function cancel() {
  emit("cancel");
}
function fetchTeam() {
  api.get(`teams/${props.teamId}`).then((json) => {
    const team = json.data;

    name.value = team.name;
    role.value = team.role;
    canSubsidize.value = team.canSubsidize;
    subsidizing.value = team.subsidizing;
    subscriptionId.value = team.subscriptionId;
    teamLoaded.value = true;
  });
}
function fetchInvitations() {
  invitations.value = [];
  invitationsLoaded.value = false;
  api.get(`teams/${props.teamId}/team_invitations`).then((json) => {
    invitations.value = json.data;
    invitationsLoaded.value = true;
  });
}
function fetchMembers() {
  members.value = [];
  membersLoaded.value = false;
  api.get(`teams/${props.teamId}/team_memberships`).then((json) => {
    members.value = json.data;
    membersLoaded.value = true;
  });
}
function addUser(person) {
  let newContacts = [];
  if (person.className === "RawEmail") {
    delete person.id;
    newContacts = _.unionBy([person], invitations.value, "name");
    invitations.value = newContacts;
  } else {
    newContacts = _.unionBy([person], members.value, "name");
    members.value = newContacts;
  }

  // this.$store.dispatch(
  //   "flash",
  //   "New member added. Save the team to send invitations."
  // );
}
function removePerson(person) {
  const newContacts = members.value.filter((c) => c.name !== person.name);

  members.value = newContacts;
  document.getElementById(`team-form-autocomplete-input`).focus();
}
function cancelSubscription() {
  if (subscriptionId.value) {
    api.delete(`team_subscriptions/${subscriptionId.value}`).then(
      () => {
        subsidizing.value = false;
        subscriptionId.value = null;
      },
      () => {
        subsidizing.value = true;
      }
    );
  }
}
async function update() {
  setTimeout(async () => {
    if (emailUnverified.value) {
      userStore.promptToVerify();
    } else if (complete.value) {
      const teamPayload = { name: name.value };
      const membersPayload = {
        members: members.value.filter((person) => !person.memberId),
        invitations: invitations.value.filter((invitee) => !invitee.token),
      };

      await Promise.all([
        api.patch(`teams/${props.teamId}`, teamPayload),
        api.post(`teams/${props.teamId}/team_memberships`, membersPayload),
      ]);

      emit("saved");
    }
  }, 125);
}
function save() {
  setTimeout(() => {
    if (emailUnverified.value) {
      userStore.promptToVerify();
    } else if (complete.value) {
      api.post(`teams`, persistPayload.value).then(() => {
        emit("saved");
      });
    }
  }, 125);
}
</script>
