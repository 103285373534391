<template>
  <li :data-test="`${snakeCaseProgressionValue}-milestone-container`">
    <span class="flex items-center space-x-1">
      <template v-if="stateDataField">
        <span
          v-if="progressionStyle === 'completed'"
          class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center"
        >
          <svg
            class="h-full w-full text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
        <span
          v-if="progressionStyle === 'current'"
          class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center"
        >
          <svg
            class="h-full w-full text-indigo-600 group-hover:text-indigo-800"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
        <div
          v-else-if="progressionStyle === 'upcoming'"
          class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
          aria-hidden="true"
        >
          <div
            class="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400"
          ></div>
        </div>
        <span
          v-else-if="progressionStyle === 'locked'"
          class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center"
        >
          <svg
            class="h-full w-full text-gray-300 group-hover:text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
        </span>

        <DataField
          :data-field="stateDataField"
          standalone-field-name="state"
          :dropdown-range-value="snakeCaseProgressionValue"
          :primary-text-prop-override="milestoneObject.name"
          text-classes="text-sm font-medium"
          text-styles=""
          @open-sourced="unlocked"
          @unlocked="unlocked"
          @completed="completed"
          @suggest-change="suggestStateChange"
          @set-proof="setProof"
        />
      </template>

      <div v-else class="flex items-center space-x-2">
        <DataVisibilityButton visibility="safezone">
          <template v-slot:button>
            <button
              @click="saveState"
              :disabled="originatingData"
              type="button"
              :data-test="`${snakeCaseProgressionValue}-save-button`"
              class="group py-1 px-1.5 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PulseLoader
                v-if="originatingData"
                :loading="true"
                size="3px"
                color="#f3f4f6"
              />
              <span v-else class="min-w-0 flex-1 flex items-center space-x-1">
                <span
                  v-tooltip="
                    `${milestoneObject.statusLabel || 'Unknown state'}`
                  "
                  class="block flex-shrink-0"
                >
                  <span
                    class="inline-flex items-center justify-center h-5 w-5 rounded-full bg-yellow-500"
                  >
                    <CheckIcon class="h-3 w-3 text-white" />
                  </span>
                </span>
                <span v-if="!editedDate" class="block min-w-0 flex-1">
                  <span class="block text-sm font-medium text-gray-900 truncate"
                    >Set to
                    {{ milestoneObject.statusLabel || "Unknown state" }}</span
                  >
                </span>
              </span>
            </button>
          </template>
        </DataVisibilityButton>
      </div>
      <template v-if="dateField && !editingDate">
        <CalendarDaysIcon class="flex-shrink-0 h-5 w-5 text-gray-400" />
        <DataField
          v-if="subleaseId && subleaseExpirationDate"
          calculation-name="sublease_expiration_date"
          :calculation-value="
            dateField.fieldValue
              ? moment(dateField.fieldValue).format('MMM D, YYYY')
              : null
          "
          :bundle-field-ids="[dateField.localId]"
          text-classes="text-sm font-medium"
          @open-sourced="unlocked"
          @unlocked="fetchDateField({ override: true })"
          @completed="fetchDateField({ override: true })"
          @suggest-change="suggestDateChange"
          @set-proof="setProof"
        />
        <DataField
          v-else
          :data-field="dateField"
          standalone-field-name="date"
          text-classes="text-sm font-medium"
          @open-sourced="unlocked"
          @unlocked="fetchDateField({ override: true })"
          @completed="fetchDateField({ override: true })"
          @suggest-change="suggestDateChange"
          @set-proof="setProof"
        />
      </template>
      <ContentDateEdit
        v-else
        v-bind="dateProofProps"
        @completed="completedDate"
        @cancel="cancelDate"
      />
    </span>
  </li>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/24/outline";
import DataField from "@/components/crowdsourcing/DataField.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { ref, computed, onMounted, watch } from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import moment from "moment";
import api from "@/router/api";
import _ from "lodash";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { storeToRefs } from "pinia";
import ContentDateEdit from "@/components/crowdsourcing/ContentDateEdit.vue";
import ContentMilestoneDropdown from "@/components/crowdsourcing/ContentMilestoneDropdown.vue";
import { useRoute } from "vue-router";
import { CalendarDaysIcon } from "@heroicons/vue/20/solid";

const props = defineProps([
  "decoratingDataField",
  "stateDataField",
  "milestoneObject",
  "allMilestones",
  "fieldNameModifier",
]);
const emit = defineEmits(["suggest-state-change", "completed", "unlocked"]);

const propertyDiagramStore = usePropertyDiagramStore();
const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(
  crowdsourcedChangeGroupStore,
);
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder } = storeToRefs(spaceUsageBuilderStore);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);
const proveForValueStore = useProveForValueStore();
const timeTravelStore = useTimeTravelStore();

const dateField = ref(null);
const progressionStyle = ref("locked");
const editingDate = ref(false);
const editedDate = ref(null);

const contentType = computed(() => props.decoratingDataField.fieldContentType);
const contentId = computed(() => props.decoratingDataField.fieldContent.id);
const subleaseExpiration = computed(
  () =>
    props.milestoneObject.value === "expired" &&
    contentType.value === "SpaceUsage" &&
    props.decoratingDataField.fieldContent?.spaceType === "SpaceUsage",
);
const subleaseId = computed(() => {
  if (subleaseExpiration.value) {
    return props.decoratingDataField.fieldContent.directLeaseId;
  } else {
    return null;
  }
});
const subleaseExpirationDate = computed(() => {
  if (subleaseId.value && dateField.value) {
    return dateField.value.decoratingContentId === subleaseId.value;
  } else {
    return false;
  }
});
const snakeCaseProgressionValue = computed(
  () => props.milestoneObject.dateValue || props.milestoneObject.value,
);
const unlockable = computed(() => {
  return _.get(props.stateDataField, "price");
});
const dateFieldSnakeCaseName = computed(() => {
  return `${snakeCaseProgressionValue.value}_date`;
});
const existingDate = computed(() => {
  return _.get(dateField.value, "fieldValue", null);
});
const dateDismissalId = computed(() => {
  return _.get(dateField.value, "localId", null);
});

const dateProofProps = computed(() => {
  return {
    fieldName: "date",
    fieldNameModifier: props.fieldNameModifier,
    focus: false,
    milestoneObject: props.milestoneObject,
    existingDate: existingDate.value,
    dateDismissalId: dateDismissalId.value,
    fetchRequestKey: fetchRequestKey.value,
    decoratingDataField: props.decoratingDataField,
  };
});
const stateProofProps = computed(() => {
  return {
    fieldNameModifier: props.fieldNameModifier || "state",
    existingState: props.stateDataField,
    fetchRequestKey: fetchRequestKey.value,
    decoratingDataField: props.decoratingDataField,
  };
});

watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);
  console.log("content milestone licenses channel watcher", data);

  if (data.dataFieldIds) {
    dealBuilderStore.dropFetchRequest(fetchRequestKey.value);
    spaceUsageBuilderStore.dropFetchRequest(fetchRequestKey.value);

    if (_.includes(data.dataFieldIds, dateField.value?.localId)) {
      fetchDateField();
    }
  }
});

onMounted(() => {
  if (!unlockable.value) {
    setProgressionStyle();
  }

  fetchDateField();
});

function setProof(fieldName) {
  let matchedProps = {};

  if (_.includes(["state", "contract_state", "occupancy_state"], fieldName)) {
    matchedProps = stateProofProps.value;
  } else {
    matchedProps = _.merge({}, dateProofProps.value, { focus: true });
  }

  if (matchedProps && proofComponentFor(fieldName)) {
    proveForValueStore.setProofComponent(
      proofComponentFor(fieldName),
      matchedProps,
    );
  }
}

function proofComponentFor(fieldName) {
  switch (fieldName) {
    case "state":
    case "contract_state":
    case "occupancy_state":
      return ContentMilestoneDropdown;
    default:
      return ContentDateEdit;
  }
}

function setProgressionStyle() {
  const currentState = _.find(props.allMilestones, {
    value: _.get(props.stateDataField, "fieldValue"),
  });
  const valuesExist = currentState && props.milestoneObject;

  if (valuesExist) {
    const currentOrder = currentState.order;
    const progressionOrder = props.milestoneObject.order;
    const completed = progressionOrder < currentOrder;
    const current = progressionOrder === currentOrder;
    const upcoming = progressionOrder > currentOrder;
    const skipped = completed && props.milestoneObject.value === "withdrawn";

    if (skipped) {
      progressionStyle.value = "upcoming";
    } else if (completed) {
      progressionStyle.value = "completed";
    } else if (current) {
      progressionStyle.value = "current";
    } else if (upcoming) {
      progressionStyle.value = "upcoming";
    }
  }
}

function completedDate(json) {
  updateDateField(json?.data);
  finalizeChange(json);
}

function updateDateField({ dataFields }) {
  if (dataFields) {
    cancelDate();
    const dateDataField = _.find(dataFields, {
      fieldValueType: "Date",
      deprecatedAt: null,
    });
    const stateDataField = _.find(dataFields, {
      fieldName: "state",
      deprecatedAt: null,
    });
    dateField.value = dateDataField;

    dealBuilderStore.clearDealBuilder(false);
    spaceUsageBuilderStore.clearBuilder(false);
    completed(stateDataField);
  } else if (!props.stateDataField) {
    dealBuilderStore.clearDealBuilder(false);
    spaceUsageBuilderStore.clearBuilder(false);
    completed();
  }

  if (dateFieldSnakeCaseName.value === "expired_date") {
    timeTravelStore.triggerRefetch();
  }
}

const fetchRequestKey = computed(
  () =>
    `crowdsourced_data_fields_${contentType.value}_${contentId.value}?field_name=${dateFieldSnakeCaseName.value}`,
);
const route = useRoute();
const query = computed(() => route.query);
const horizontalIsDeals = computed(
  () => _.get(query.value, "horizontalTab") === "Deals",
);
const horizontalIsSpaces = computed(
  () => _.get(query.value, "horizontalTab") === "Spaces",
);

function fetchDateField(maybePayload) {
  console.log("fetch content milestone date field", maybePayload);
  if (
    horizontalIsDeals.value &&
    dealBuilderStore.alreadyFetched(fetchRequestKey.value) &&
    !maybePayload?.override
  ) {
    const alreadyFetchedFields = dealBuilderStore.alreadyFetchedFieldsFor(
      fetchRequestKey.value,
    );
    const dateDataField = _.find(alreadyFetchedFields, {
      fieldValueType: "Date",
      deprecatedAt: null,
    });

    if (dateDataField) dateField.value = dateDataField;
  } else if (
    horizontalIsSpaces.value &&
    spaceUsageBuilderStore.alreadyFetched(fetchRequestKey.value) &&
    !maybePayload?.override
  ) {
    const alreadyFetchedFields = spaceUsageBuilderStore.alreadyFetchedFieldsFor(
      fetchRequestKey.value,
    );
    const dateDataField = _.find(alreadyFetchedFields, {
      fieldValueType: "Date",
      deprecatedAt: null,
    });

    if (dateDataField) dateField.value = dateDataField;
  } else {
    api
      .get(
        `crowdsourced_data_fields/${contentType.value}/${contentId.value}?field_name=${dateFieldSnakeCaseName.value}`,
      )
      .then((json) => {
        if (dealBuilder.value && json.data) {
          if (maybePayload?.override) {
            dealBuilderStore.clearDealBuilder(false);
          }

          dealBuilderStore.interceptablePatch(
            [json.data],
            fetchRequestKey.value,
          );
          dateField.value = json.data;
        } else if (spaceUsageBuilder.value && json.data) {
          if (maybePayload?.override) {
            spaceUsageBuilderStore.clearBuilder(false);
          }

          spaceUsageBuilderStore.interceptablePatch(
            [json.data],
            fetchRequestKey.value,
          );
          dateField.value = json.data;
        } else if (subleaseId.value) {
          fetchSubleaseExpirationDate(maybePayload);
        } else {
          dateField.value = json.data;
        }
      });
  }
}

function fetchSubleaseExpirationDate(maybePayload) {
  if (subleaseId.value) {
    api
      .get(
        `crowdsourced_data_fields/SpaceUsage/${subleaseId.value}?field_name=${dateFieldSnakeCaseName.value}`,
      )
      .then((json) => {
        if (spaceUsageBuilder.value && json.data) {
          if (maybePayload?.override) {
            spaceUsageBuilderStore.clearBuilder(false);
          }

          spaceUsageBuilderStore.interceptablePatch(
            [json.data],
            fetchRequestKey.value,
          );
        }
        dateField.value = json.data;
      });
  }
}

function saveState() {
  let payload = {
    fieldValue: snakeCaseProgressionValue.value,
    changeGroupId: changeGroupId.value,
  };

  if (props.fieldNameModifier !== "state") {
    payload.fieldNameModifier = props.fieldNameModifier;
  }

  const apiRequestFunc = () =>
    api.post(`content_states/${contentType.value}/${contentId.value}`, payload);
  const successCallback = (json) => afterPersistState(json);
  const failureCallback = () => {};

  return crowdsourcedChangeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}

async function afterPersistState(json) {
  updateDateField(json.data);
  finalizeChange(json);
}

function suggestDateChange() {
  editingDate.value = true;
}
function suggestStateChange() {
  emit("suggest-state-change");
}
function cancelDate() {
  editingDate.value = false;
}
function completed(maybePayload) {
  emit("completed", maybePayload);
}
function unlocked(maybePayload) {
  const overrideIncluded = _.merge({}, maybePayload, { override: true });
  emit("unlocked", overrideIncluded);
}

async function finalizeChange(json) {
  propertyDiagramStore.postEditingPatch(json);
  await dealBuilderStore.postEditingPatch(json, fetchRequestKey.value);
  await spaceUsageBuilderStore.postEditingPatch(json, fetchRequestKey.value);
  completed();
}
</script>
