<template>
  <VDropdown :disabled="emailUnverified || !signedIn" placement="left-start">
    <button
      type="button"
      class="flex-shrink-0 inline-flex items-center gap-x-2 rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-md hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
    >
      <PaperAirplaneIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
      Invite Data Partner
    </button>

    <template #popper>
      <div class="w-80">
        <div class="p-2 space-y-2">
          <div class="flex items-center space-x-1">
            <UsersIcon class="h-5 w-5 text-gray-900" />
            <label class="text-base font-medium text-gray-900"
              >Partner with a Referrer to Add Data</label
            >
          </div>
          <p class="text-xs leading-5 text-gray-500">
            The fast and fair way to onboard data that comes up on the fly
            during conversation.
            <a
              href=""
              @click.prevent
              class="font-medium text-indigo-700 underline hover:text-indigo-600"
              >Learn more.</a
            >
          </p>
        </div>
        <fieldset class="p-2 w-full">
          <div class="space-y-1">
            <label for="invitee" class="block text-sm font-medium text-gray-900"
              >Invitee (Referrer)</label
            >
            <div>
              <div
                v-if="selections.length > 0"
                class="inline-flex rounded-full items-center my-1 mr-1 py-0.5 pl-2.5 pr-1 text-sm font-medium bg-orange-100 text-orange-700"
              >
                {{ _.head(selections)?.name }}
                <button
                  @click="clearSelection"
                  type="button"
                  class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-orange-400 hover:bg-orange-200 hover:text-orange-500 focus:outline-none focus:bg-orange-500 focus:text-white"
                >
                  <span class="sr-only">Remove invitee</span>
                  <svg
                    class="h-2 w-2"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-width="1.5"
                      d="M1 1l6 6m0-6L1 7"
                    />
                  </svg>
                </button>
              </div>
              <div v-else class="focus-within:z-10">
                <UserTeamAutocomplete
                  :search-teams="false"
                  :selections="_.concat(members, invitations)"
                  :remove-inline="false"
                  input-classes="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  input-key="team-form"
                  :focus="true"
                  :no-self="true"
                  @add-user="addUser"
                />
              </div>
            </div>
          </div>

          <Listbox as="div" v-model="complexity" class="mt-2">
            <ListboxLabel class="block text-sm/4 font-medium text-gray-900"
              >Input Complexity</ListboxLabel
            >
            <div class="relative mt-1">
              <ListboxButton
                class="relative w-full cursor-default rounded-md bg-white py-2 pl-2 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-600 sm:text-sm/4"
              >
                <span class="flex items-center">
                  <span
                    :aria-label="complexity.name"
                    :class="[
                      complexity.color,
                      'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                    ]"
                  />
                  <span class="ml-3 block truncate">{{ complexity.name }}</span>
                </span>
                <span
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions
                  class="absolute z-50 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                    as="template"
                    v-for="level in inputComplexityLevels"
                    :key="level.id"
                    :value="level"
                    v-slot="{ active, selected }"
                  >
                    <li
                      :class="[
                        active ? 'bg-orange-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-1 pl-2 pr-9',
                      ]"
                    >
                      <div class="flex items-center">
                        <span
                          :class="[
                            level.color,
                            'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                          ]"
                          aria-hidden="true"
                        />
                        <div class="flex flex-col">
                          <span
                            :class="[
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate',
                            ]"
                          >
                            {{ level.name }}
                          </span>
                          <span
                            :class="[
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 text-xs block truncate',
                            ]"
                          >
                            {{ level.description }}
                          </span>
                        </div>
                      </div>

                      <span
                        v-if="selected"
                        :class="[
                          active ? 'text-white' : 'text-orange-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        ]"
                      >
                        <CheckIcon class="h-4 w-4" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>

          <div class="w-full mt-2">
            <!-- Text -->
            <legend class="sr-only">Narrative</legend>
            <div class="space-y-1">
              <div class="flex items-center justify-between">
                <label
                  for="narrative"
                  class="block text-sm font-medium text-gray-900"
                  >Narrative</label
                >
              </div>
              <textarea
                v-model="narrative"
                rows="5"
                name="narrative"
                id="narrative"
                placeholder="Describe what needs to be added. (optional)"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 text-xs"
              />
            </div>
          </div>
        </fieldset>

        <div class="p-2 bg-gray-50 flex flex-col items-end">
          <div class="flex items-center space-x-2">
            <button
              @click="cancel"
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              @click="save"
              :disabled="selections.length === 0"
              type="button"
              class="bg-orange-600 hover:bg-orange-700 focus:ring-orange-500 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <span>Invite</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import UserTeamAutocomplete from "@/components/contacts/UserTeamAutocomplete.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import {
  CheckIcon,
  ChevronUpDownIcon,
  PaperAirplaneIcon,
  UsersIcon,
} from "@heroicons/vue/20/solid";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";

const userStore = useUserStore();
const { signedIn, emailUnverified, emailVerified } = storeToRefs(userStore);
const notificationsStore = useNotificationsStore();

const invitations = ref([]);
const members = ref([]);
const selections = computed(() => _.concat(members.value, invitations.value));

const inputComplexityLevels = [
  {
    id: 1,
    name: "Low",
    description: "Singletons, autocompletes",
    color: "bg-green-400",
  },
  {
    id: 2,
    name: "Medium",
    description: "Multi-physical, Deals",
    color: "bg-amber-400",
  },
  {
    id: 3,
    name: "High",
    description: "Groups, Air Rights, Diagram Art",
    color: "bg-red-400",
  },
];

const complexity = ref(inputComplexityLevels[0]);
const narrative = ref("");

function clearSelection() {
  invitations.value = [];
  members.value = [];
}

function addUser(person) {
  let newContacts = [];
  if (person.className === "RawEmail") {
    delete person.id;
    newContacts = _.unionBy([person], invitations.value, "name");
    invitations.value = newContacts;
  } else {
    newContacts = _.unionBy([person], members.value, "name");
    members.value = newContacts;
  }
}

function cancel() {
  clearSelection();
  complexity.value = inputComplexityLevels[0];
  narrative.value = "";
}
async function save() {
  if (emailVerified.value && selections.value.length > 0) {
    const payload = {
      invitee: _.head(selections.value),
      complexity: complexity.value.name,
      narrative: _.trim(narrative.value) !== "" ? narrative.value : null,
    };

    const response = await api.post(`easy_data_input_job_invitations`, payload);

    if (response?.data) {
      notificationsStore.addNotification("easyDataInviteSent");
      cancel();
    }
  }
}
</script>
