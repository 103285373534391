<template>
  <div
    v-if="rowObject"
    class="pointer-events-none grid"
    :class="[internalName === 'Air Rights' ? 'bg-sky-100' : '']"
    :style="`grid-template-columns: ${gridWidths}; z-index: 1;`"
  >
    <template v-if="internalName === 'Air Rights'">
      <AirRightGridBlock
        v-for="(fieldObject, index) in columnDataFields"
        :key="`${rowObject.loopKeyId}-${index}-block`"
        :test-key="`${layer?.order ? `layer-${layer.order}-` : ''}${
          rowObject.loopKeyId
        }-${index}-block`"
        :property-id="propertyId"
        :data-field="typeBasedFieldFor(fieldObject)"
        :floor-areas="floorAreasFor(typeBasedFieldFor(fieldObject))"
        :background-style="
          diagramContentSelectionStore.propertyRightSelectionBackgroundStylingFor(
            {
              propertyId,
              dataField: typeBasedFieldFor(fieldObject),
            },
          )
        "
        @clicked="
          diagramContentSelectionStore.feeSimpleColumnClickHandler({
            propertyId,
            dataField: typeBasedFieldFor(fieldObject),
          })
        "
        @floorless-floor-area-click="
          diagramContentSelectionStore.singleClickSelect
        "
        @refetch="refetchCoverings({ rights: 'air' })"
      />
    </template>
    <template v-else>
      <VMenu
        theme="diagram-popup"
        :disabled="editingMode"
        v-for="(fieldObject, index) in columnDataFields"
        :key="`${rowObject.loopKeyId}-${index}-block`"
        :test-key="`${layer?.order ? `layer-${layer.order}-` : ''}${
          rowObject.loopKeyId
        }-${index}-block`"
      >
        <PropertyRightGridBlock
          :property-id="propertyId"
          :data-field="typeBasedFieldFor(fieldObject)"
          :enhancements="enhancementsFor(typeBasedFieldFor(fieldObject))"
          :right-type="rightType"
          :background-style="
            diagramContentSelectionStore.propertyRightSelectionBackgroundStylingFor(
              {
                propertyId,
                dataField: typeBasedFieldFor(fieldObject),
              },
            )
          "
          @clicked="
            diagramContentSelectionStore.feeSimpleColumnClickHandler({
              propertyId,
              dataField: typeBasedFieldFor(fieldObject),
            })
          "
          @property-enhancement-click="
            diagramContentSelectionStore.singleClickSelect
          "
        />
        <template #popper>
          <DataFieldInfoPopup :data-field="typeBasedFieldFor(fieldObject)" />
        </template>
      </VMenu>
    </template>
  </div>
</template>

<script setup>
import AirRightGridBlock from "@/components/property-diagram/AirRightGridBlock.vue";
import PropertyRightGridBlock from "@/components/property-diagram/PropertyRightGridBlock.vue";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import getFieldChildrenFromCollection from "@/assets/getFieldChildrenFromCollection";
import { computed } from "vue";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { usePropertyDiagramContentSelectionStore } from "@/stores/propertyDiagramContentSelection";
import { storeToRefs } from "pinia";
import _ from "lodash";

const props = defineProps(["internalName", "propertyId", "layer"]);
const diagramStore = usePropertyDiagramStore();
const {
  diagramAllVisibleFields,
  renderableGridRows,
  propertyEnhancements,
  editingMode,
} = storeToRefs(diagramStore);
const diagramContentSelectionStore = usePropertyDiagramContentSelectionStore();

const rowObject = computed(() =>
  _.find(renderableGridRows.value, {
    internalName: airLayerInternalNameOverride.value || props.internalName,
  }),
);
const rightType = computed(() => {
  switch (airLayerInternalNameOverride.value || props.internalName) {
    case "Fee Parcels":
      return "Fee simple";
    case "Fee Leaseholds":
    case "Land Covering Leaseholds":
      return "Leasehold";
    case "Reciprocal Easement Agreements":
      return "Reciprocal Easement Agreement";
    case "Land Covering Ownership Units":
      return "Condominium or Co-op";
    case "Air Rights":
      return "Unused Development Rights";
    default:
      return null;
  }
});
const groundLayerPropertyId = computed(() => {
  const propertyDataField = diagramStore.propertyDataFieldFor(props.propertyId);
  return propertyDataField?.fieldContent?.groundLayerPropertyId;
});
const displayablePropertyId = computed(
  () => groundLayerPropertyId.value || props.propertyId,
);
const airLayerInternalNameOverride = computed(() =>
  groundLayerPropertyId.value && props.internalName === "Fee Parcels"
    ? "Air Rights"
    : null,
);
const feeSimples = computed(() =>
  diagramStore.orderedFeeSimples(displayablePropertyId.value),
);
const airRights = computed(() =>
  diagramStore.orderedAirRights(displayablePropertyId.value),
);
const floorAreas = computed(() => {
  return diagramAllVisibleFields.value.filter((dataField) => {
    return (
      dataField.fieldContentType === "FloorArea" &&
      dataField.joiningContentType === "Property" &&
      dataField.joiningContentId === displayablePropertyId.value
    );
  });
});
const scopedAirRights = computed(() => {
  if (groundLayerPropertyId.value) {
    const airRights = diagramStore.subTypedDataFields([
      "unused_development_rights",
    ]);
    return feeSimples.value.flatMap((dataField) => {
      return airRightsFor(dataField, airRights);
    });
  } else {
    return [];
  }
});
const columnDataFields = computed(() => {
  switch (airLayerInternalNameOverride.value || props.internalName) {
    case "Fee Parcels":
      return feeSimples.value;
    case "Fee Leaseholds": {
      if (groundLayerPropertyId.value) {
        return scopedAirRights.value.map((airRightsDataField) => {
          return feeSimpleMatchFor(
            airRightsDataField,
            scopedFieldsFor(props.layer.dataFields),
          );
        });
      } else {
        return feeSimples.value.map((dataField) => {
          return feeSimpleMatchFor(
            dataField,
            scopedFieldsFor(props.layer.dataFields),
          );
        });
      }
    }
    case "Air Rights": {
      if (airLayerInternalNameOverride.value) {
        return feeSimples.value.flatMap((dataField) => {
          return airRightsFor(
            dataField,
            airLayerScopedFieldsFor(airRights.value),
          );
        });
      } else {
        return feeSimples.value.flatMap((dataField) => {
          return airRightsFor(dataField, scopedFieldsFor(airRights.value));
        });
      }
    }
    case "Reciprocal Easement Agreements":
      return orderedReciprocalEasementAgreements(
        feeSimples.value,
        scopedFieldsFor(rowObject.value.dataFields),
      );
    case "Land Covering Ownership Units": {
      if (groundLayerPropertyId.value) {
        const airRights = diagramStore.subTypedDataFields([
          "unused_development_rights",
        ]);
        const scopedAirRights = feeSimples.value.flatMap((dataField) => {
          return airRightsFor(dataField, airRights);
        });
        return scopedAirRights.map((dataField) => {
          return feeSimpleMatchFor(
            dataField,
            scopedFieldsFor(rowObject.value.dataFields),
          );
        });
      } else {
        return feeSimples.value.map((dataField) => {
          return feeSimpleMatchFor(
            dataField,
            scopedFieldsFor(rowObject.value.dataFields),
          );
        });
      }
    }
    default:
      return [];
  }
});
const gridWidths = computed(() => {
  switch (airLayerInternalNameOverride.value || props.internalName) {
    case "Air Rights": {
      if (
        diagramStore.propertyIsAirLayer(props.propertyId) &&
        props.internalName === "Air Rights"
      ) {
        return diagramStore.gridWidths(displayablePropertyId.value);
      } else {
        return diagramStore.airLayerParcelWidths(
          columnDataFields.value,
          displayablePropertyId.value,
        );
      }
    }
    case "Fee Leaseholds": {
      if (diagramStore.propertyIsAirLayer(props.propertyId)) {
        return diagramStore.airLayerParcelWidths(
          scopedAirRights.value,
          displayablePropertyId.value,
        );
      } else {
        return diagramStore.gridWidths(displayablePropertyId.value);
      }
    }
    case "Reciprocal Easement Agreements": {
      const easements = orderedReciprocalEasementAgreements(
        feeSimples.value,
        scopedFieldsFor(rowObject.value.dataFields),
      );

      return easements
        .map((easementObject) => `${easementObject.gridWidth}%`)
        .join(" ");
    }
    default:
      return diagramStore.gridWidths(displayablePropertyId.value);
  }
});

function airLayerScopedFieldsFor(
  allFields,
  comparisonType = "joiningContentId",
) {
  return allFields.filter((dataField) => {
    return _.get(dataField, comparisonType) === groundLayerPropertyId.value;
  });
}

function scopedFieldsFor(allFields, comparisonType = "joiningContentId") {
  return allFields.filter((dataField) => {
    return _.get(dataField, comparisonType) === props.propertyId;
  });
}
function feeSimpleMatchFor(dataField, rights) {
  const rightId = _.get(dataField, "fieldContentId", null);

  if (rightId) {
    return (
      _.find(rights, function (df) {
        return _.get(df, "fieldContent.feeSimpleId", null) === rightId;
      }) || null
    );
  } else {
    return null;
  }
}
function floorAreasFor(airRightDataField) {
  const rightId = _.get(airRightDataField, "fieldContentId", null);

  if (rightId) {
    return _.filter(floorAreas.value, function (df) {
      return (
        _.includes(_.get(df, "fieldContent.propertyRightIds", []), rightId) &&
        df.decoratingContentId == rightId
      );
    });
  } else {
    return [];
  }
}
function airRightsFor(feeSimpleDataField, rights) {
  const rightId = _.get(feeSimpleDataField, "fieldContentId", null);

  if (rightId) {
    const matchingAirRights = _.filter(rights, function (df) {
      return _.get(df, "fieldContent.feeSimpleId", null) === rightId;
    });

    return matchingAirRights.length > 0 ? matchingAirRights : [null];
  } else {
    return [null];
  }
}
function orderedReciprocalEasementAgreements(feeSimples, rights) {
  const superiorsPlusWidths = feeSimples.map((df, index) => {
    return {
      reciprocalId: _.get(df, "fieldContent.superiorId", null),
      gridWidth: diagramStore.gridWidthsArray(props.propertyId)[index],
    };
  });
  let orderedReciprocals = [];

  superiorsPlusWidths.forEach((reciprocalObject) => {
    updateOrderedReciprocals(reciprocalObject, rights, orderedReciprocals);
  });

  return orderedReciprocals;
}

function updateOrderedReciprocals(
  { reciprocalId, gridWidth },
  reciprocalEasements,
  orderedReciprocals,
) {
  let existing = _.find(orderedReciprocals, { reciprocalId });

  if (reciprocalId && existing) {
    existing.gridWidth = _.round(existing.gridWidth + gridWidth, 1);
  } else if (reciprocalId) {
    orderedReciprocals.push({
      reciprocalId,
      reciprocalDataField: _.find(reciprocalEasements, function (df) {
        return _.get(df, "fieldContentId", null) === reciprocalId;
      }),
      gridWidth,
    });
  } else {
    orderedReciprocals.push({
      reciprocalId,
      reciprocalDataField: null,
      gridWidth,
    });
  }
}
function typeBasedFieldFor(fieldObject) {
  return props.internalName === "Reciprocal Easement Agreements"
    ? fieldObject.reciprocalDataField
    : fieldObject;
}

function enhancementsFor(focalDataField) {
  return getFieldChildrenFromCollection(
    focalDataField,
    propertyEnhancements.value,
  );
}
</script>
