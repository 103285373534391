<template>
  <section aria-labelledby="content-players">
    <ContentHeader>
      <template v-slot:title>Players</template>
      <template v-slot:description
        >Link contacts, partners, and advisors to this
        {{ _.startCase(dataField.fieldContentType) }}.</template
      >
    </ContentHeader>
    <div class="bg-white overflow-hidden">
      <div class="p-2">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8">
          <div v-if="dataField.fieldContentType !== 'Loan'" class="col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ companyLabel ? `${companyLabel} ` : "" }}Contacts
            </dt>
            <dd class="mt-1">
              <ContentContacts
                :data-field="dataField"
                :content-data-field="contentDataField"
                :context="context"
              />
            </dd>
          </div>
          <AdvisorsDetail
            :data-field="dataField"
            :fetch-milliseconds="asOfMilliseconds"
            :clientRole="advisorClientRole"
            :context="advisorContext"
          />
        </dl>
      </div>
    </div>
  </section>
</template>

<script setup>
import AdvisorsDetail from "@/components/deal-builder/AdvisorsDetail.vue";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import ContentContacts from "@/components/crowdsourcing/ContentContacts.vue";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["dataField", "contentDataField", "context"]);

const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);
const companyDetailStore = useCompanyDetailStore();
const { companyIdParam, companyDataField } = storeToRefs(companyDetailStore);

// contexts: detailsTab, loanDetail
// dataField.fieldContentType: Hunt, Loan

const advisorClientRole = computed(() => {
  switch (props.dataField.fieldContentType) {
    case "Loan":
      return "loan";
    default:
      return "hunter";
  }
});
const advisorContext = computed(() => {
  switch (props.dataField.fieldContentType) {
    case "Loan":
      return "loan-advisor";
    default:
      return "hunt-advisor";
  }
});
const companyLabel = computed(() => {
  if (companyIdParam.value && companyDataField.value) {
    return companyDataField.value.fieldContent.name;
  } else {
    return null;
  }
});
</script>
