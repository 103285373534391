<template>
  <tr v-if="landCoveringDataField" class="bg-orange-50">
    <td>
      <span class="sr-only">Empty</span>
    </td>
    <td class="whitespace-nowrap py-2 pr-3 pl-4">
      <div class="min-w-[200px] flex items-center">
        <DataField
          :data-field="landCoveringDataField"
          primary-text-path="fieldContent.name"
          text-classes="text-sm font-medium max-w-[325px]"
          :text-wrap="true"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="refetchMap"
        />
      </div>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
      <template v-if="groupConstructionStatus">{{
        groupConstructionStatus
      }}</template>
      <template v-else-if="constructionStatus">{{
        constructionStatus
      }}</template>
      <span class="sr-only">Empty</span>
    </td>
    <td v-if="false" class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
      Coming Soon
    </td>
    <td class="p-1 flex flex-wrap space-x-1">
      <SizesUses
        :standalone-content-type="landCoveringDataField.fieldContentType"
        :standalone-content-ids="
          _.compact([landCoveringDataField.fieldContentId])
        "
      />
    </td>
    <td
      v-if="false"
      class="relative whitespace-nowrap py-2 pl-3 text-right text-sm font-medium"
    >
      <a href="" @click.prevent class="text-indigo-600 hover:text-indigo-900"
        >Edit<span class="sr-only"
          >, {{ landCoveringDataField.localId }}</span
        ></a
      >
    </td>
  </tr>

  <tr
    v-if="locationDataField"
    v-observe-visibility="{ callback: fetchPropertyDataField, once: true }"
  >
    <td
      v-if="
        landCoveringDataFields.length > 1 &&
        eligibleLandCoveringDataFields.length > 0
      "
      class="align-middle relative whitespace-nowrap py-2 px-3 text-sm font-medium"
    >
      <button
        @click="expanded = !expanded"
        type="button"
        v-tooltip="expanded ? 'Hide land coverings' : 'View land coverings'"
        class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
      >
        <ChevronDownIcon class="h-5 w-5" />
      </button>
    </td>
    <td v-else>
      <span class="sr-only">Empty</span>
    </td>
    <td
      v-if="propertyDataField"
      @mouseenter="pulseProperty"
      @mouseleave="propertyMarkerPulseId = null"
      class="whitespace-nowrap py-2 pr-3"
    >
      <div v-if="propertyDataField" class="min-w-[200px] flex items-center">
        <DataField
          :data-field="propertyDataField"
          primary-text-path="fieldContent.name"
          text-classes="text-sm font-medium max-w-[325px]"
          :text-wrap="true"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="refetchMap"
        />
      </div>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
      <DataField
        :data-field="locationDataField"
        text-classes="text-sm"
        :analyze="true"
      />
    </td>
    <td v-if="false" class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
      Coming Soon
    </td>
    <td class="p-1 flex flex-wrap space-x-1">
      <SizesUses
        standalone-content-type="Property"
        :standalone-content-ids="_.compact([propertyId, airLayerPropertyId])"
      />
    </td>
    <td
      v-if="false"
      class="relative whitespace-nowrap py-2 pl-3 text-right text-sm font-medium"
    >
      <a href="" @click.prevent class="text-indigo-600 hover:text-indigo-900"
        >Edit<span class="sr-only">, {{ propertyDataField.localId }}</span></a
      >
    </td>
  </tr>

  <template v-if="expanded">
    <PropertyTableRow
      v-for="dataField in eligibleLandCoveringDataFields"
      :key="`${propertyDataField.localId}_LandCovering${dataField.fieldContentId}`"
      :land-covering-data-field="dataField"
      :group-construction-status="groupingConstructionStatus(dataField)"
    />
  </template>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import SizesUses from "@/components/analyze/calculations/SizesUses.vue";
import { useMainMapStore } from "@/stores/mainMap";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import milestones from "@/assets/contentMilestones";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import api from "@/router/api";
import _ from "lodash";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";

const props = defineProps([
  "locationDataField",
  "includeAirRights",
  "landCoveringDataField",
  "groupConstructionStatus",
]);
const analyzePanelStore = useAnalyzePanelStore();
const {
  analyzePropertyFields,
  checkedCategories,
  combinedFilteredLandCoveringIds,
} = storeToRefs(analyzePanelStore);
const propertyDataField = computed(() => {
  if (props.locationDataField) {
    return propertyFieldsStore.getPropertyField(
      props.locationDataField.decoratingContentId,
    );
  } else {
    return null;
  }
});
const mapStore = useMainMapStore();
const { propertyMarkerPulseId } = storeToRefs(mapStore);
const propertyFieldsStore = usePropertyFieldsStore();
const { asOfMilliseconds } = storeToRefs(useTimeTravelStore());

const constructionStatus = computed(() => {
  if (props.landCoveringDataField?.fieldContent?.state) {
    const rawState = props.landCoveringDataField.fieldContent.state;
    const matchingOptions = _.get(milestones, `LandCovering`, []);
    const focalOption = _.find(matchingOptions, { value: rawState });

    return focalOption?.analyzeLabel || focalOption?.statusLabel;
  } else {
    return null;
  }
});

const expanded = ref(false);
const landCoveringDataFields = ref([]);
const landCoveringGroupingDataFields = ref([]);
const eligibleLandCoveringDataFields = computed(() => {
  if (
    checkedCategories.value.length > 0 &&
    combinedFilteredLandCoveringIds.value.length > 0
  ) {
    const eligibleStandalones = landCoveringDataFields.value.filter(
      (dataField) => {
        return _.includes(
          combinedFilteredLandCoveringIds.value,
          dataField.fieldContentId,
        );
      },
    );
    const eligibleGroupingIds = eligibleStandalones.flatMap(
      (dataField) => dataField.fieldContent.groupingIds,
    );
    const eligibleGroupings = landCoveringGroupingDataFields.value.filter(
      (dataField) => {
        return _.includes(eligibleGroupingIds, dataField.fieldContent.id);
      },
    );
    const ungroupedStandalones = eligibleStandalones.filter((dataField) =>
      _.isEmpty(dataField.fieldContent.groupingIds),
    );

    return _.concat(eligibleGroupings, ungroupedStandalones);
  } else {
    return landCoveringDataFields.value;
  }
});
const propertyId = computed(() => propertyDataField.value?.fieldContentId);
const airLayerPropertyId = computed(() =>
  props.includeAirRights
    ? propertyDataField.value?.fieldContent?.airLayerPropertyId
    : null,
);

watch(propertyId, () => {
  if (propertyId.value) {
    fetchLandCoverings();
    fetchLandCoveringGroupings();
  }
});

async function fetchPropertyDataField() {
  await propertyFieldsStore.fetchPropertyDataField(
    props.locationDataField.decoratingContentId,
  );
}

function landCoveringsAsOfPayload() {
  return {
    asOf: asOfMilliseconds.value,
    fieldName: "LandCovering",
    propertyIds: _.compact([propertyId.value, airLayerPropertyId.value]),
    activeChangeGroupId: null,
  };
}
async function fetchLandCoverings() {
  if (propertyId.value) {
    const response = await api.post(
      `diagram_data_as_of_date`,
      landCoveringsAsOfPayload(),
    );

    if (response?.data) {
      landCoveringDataFields.value = response.data;
      _.forEach(response.data, function (dataField) {
        const fieldKey = `LandCovering${dataField.fieldContentId}`;
        analyzePropertyFields.value[fieldKey] = dataField;
      });
    }
  }
}

async function fetchLandCoveringGroupings() {
  if (propertyId.value) {
    const payload = {
      propertyIds: _.compact([propertyId.value, airLayerPropertyId.value]),
      activeChangeGroupId: null,
    };

    const response = await api.post(
      `property_land_covering_groupings`,
      payload,
    );

    if (response?.data) {
      landCoveringGroupingDataFields.value = response.data;
      _.forEach(response.data, function (dataField) {
        const fieldKey = `LandCoveringGrouping${dataField.fieldContentId}`;
        analyzePropertyFields.value[fieldKey] = dataField;
      });
    }
  }
}

function groupingConstructionStatus(groupingDataField) {
  if (groupingDataField.fieldContentType === "LandCoveringGrouping") {
    const eligibleCoverings = landCoveringDataFields.value.filter(
      (dataField) => {
        return (
          _.includes(
            combinedFilteredLandCoveringIds.value,
            dataField.fieldContentId,
          ) &&
          _.includes(
            groupingDataField.fieldContent.landCoveringIds,
            dataField.fieldContentId,
          )
        );
      },
    );

    const rawStates = eligibleCoverings.map((dataField) => {
      if (dataField?.fieldContent?.state) {
        const rawState = dataField.fieldContent.state;
        const matchingOptions = _.get(milestones, `LandCovering`, []);
        const focalOption = _.find(matchingOptions, { value: rawState });

        return focalOption?.analyzeLabel || focalOption?.statusLabel;
      } else {
        return null;
      }
    });

    return _.uniq(_.compact(rawStates)).join(", ");
  } else {
    return null;
  }
}

function refetchMap() {
  props.mapStore.tapView();
}

function pulseProperty() {
  if (propertyId.value) propertyMarkerPulseId.value = propertyId.value;
}
</script>
