<template>
  <div
    :class="`px-1 py-0.5 rounded-md inline-flex items-center space-x-1 text-left border-2 border-white text-sm font-medium ${dynamicClass} underline decoration-2 ${dynamicUnderlineClass}`"
  >
    <span v-if="taskLabel" :class="`${taskLabelColor}`">{{ taskLabel }}</span>
    <CalculatorIcon
      v-if="state === 'calculation'"
      class="flex-shrink-0 h-4 w-4"
    />
    <LockClosedIcon
      v-else-if="
        _.includes(
          [
            'unlockable safezone',
            'unlockable safezone shared',
            'unlockable public',
            'subscribeToView',
          ],
          state,
        )
      "
      class="flex-shrink-0 h-3 w-3"
    />
    <HandThumbDownIcon
      v-else-if="state === 'rejected'"
      class="flex-shrink-0 h-3 w-3"
    />
    <ScaleIcon
      v-else-if="_.includes(['inValidation', 'inValidation shared'], state)"
      class="flex-shrink-0 h-4 w-4"
    />
    <ShieldExclamationIcon
      v-else-if="_.includes(['unpublished', 'unpublished shared'], state)"
      class="flex-shrink-0 h-4 w-4"
    />
    <EyeSlashIcon
      v-else-if="_.includes(['safezone', 'safezone shared'], state)"
      class="flex-shrink-0 h-3 w-3"
    />
    <EyeIcon v-else-if="state === 'public'" class="flex-shrink-0 h-3 w-3" />
    <span>{{ statusLabel }}</span>
  </div>
</template>

<script setup>
import {
  EyeSlashIcon,
  EyeIcon,
  LockClosedIcon,
  HandThumbDownIcon,
  CalculatorIcon,
  ShieldExclamationIcon,
  ScaleIcon,
} from "@heroicons/vue/20/solid";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["state"]);

const statusLabel = computed(() => {
  switch (props.state) {
    case "safezone":
      return "Safezone";
    case "safezone shared":
      return "Safezone unlocked";
    case "unlockable safezone shared":
      return "Safezone locked";
    case "unstaked":
      return "Pre-validation unstaked";
    case "unpublished":
    case "unpublished shared":
      return "Pre-validation unpublished";
    case "inValidation":
    case "inValidation shared":
      return "In validation";
    case "unlockable public":
      return "Public locked";
    case "public":
      return "Public unlocked";
    case "rejected":
      return "Rejected";
    case "calculation":
      return "Calculation";
    case "subscribeToView":
      return "Subscribe to view";
    default:
      return "Datapoint";
  }
});

const taskLabel = computed(() => {
  switch (props.state) {
    case "high":
      return "!!!";
    case "medium":
      return "!!";
    case "low":
      return "!";
    default:
      return null;
  }
});
const taskLabelColor = computed(() => {
  switch (props.state) {
    case "high":
      return "text-red-500";
    case "medium":
      return "text-amber-500";
    case "low":
      return "text-green-500";
    default:
      return "text-gray-300";
  }
});
const dynamicClass = computed(() => {
  if (props.state === "calculation") {
    return "text-teal-600 bg-teal-100";
  } else if (
    _.includes(
      [
        "unlockable safezone",
        "unlockable safezone shared",
        "unstaked",
        "subscribeToView",
      ],
      props.state,
    )
  ) {
    if (
      _.includes(
        ["unlockable safezone", "unlockable safezone shared"],
        props.state,
      )
    )
      return "text-yellow-600 bg-yellow-100";
    else if (props.state === "unstaked") return "text-pink-700 bg-pink-100";
    else return "text-gray-900 bg-gray-200";
  } else if (props.state === "rejected") {
    return "text-pink-600 bg-pink-100";
  } else if (_.includes(["safezone", "safezone shared"], props.state)) {
    return "text-yellow-600 bg-yellow-100";
  } else if (_.includes(["inValidation", "inValidation shared"], props.state)) {
    return "text-orange-600 bg-orange-100";
  } else if (_.includes(["unpublished", "unpublished shared"], props.state)) {
    return "text-emerald-600 bg-emerald-100";
  } else {
    return "text-indigo-700 bg-indigo-100";
  }
});
const dynamicUnderlineClass = computed(() => {
  if (props.state === "calculation") {
    return "decoration-solid decoration-teal-500";
  } else if (
    _.includes(
      [
        "unlockable safezone",
        "unlockable safezone shared",
        "unstaked",
        "subscribeToView",
      ],
      props.state,
    )
  ) {
    if (props.state === "unlockable safezone")
      return "decoration-wavy decoration-yellow-500";
    else if (props.state === "unlockable safezone shared")
      return "decoration-wavy decoration-emerald-400";
    else if (props.state === "unstaked")
      return "decoration-dashed decoration-pink-700";
    else return "decoration-dashed decoration-gray-700";
  } else if (props.state === "rejected") {
    return "decoration-dotted decoration-pink-400";
  } else if (props.state === "safezone") {
    return "decoration-wavy decoration-yellow-500";
  } else if (props.state === "safezone shared") {
    return "decoration-wavy decoration-emerald-400";
  } else if (props.state === "inValidation") {
    return "decoration-solid decoration-orange-400";
  } else if (props.state === "inValidation shared") {
    return "decoration-wavy decoration-emerald-400";
  } else if (props.state === "unpublished") {
    return "decoration-solid decoration-emerald-400";
  } else if (props.state === "unpublished shared") {
    return "decoration-wavy decoration-emerald-400";
  } else {
    return "decoration-solid decoration-indigo-600";
  }
});
</script>
