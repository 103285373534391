<template>
  <div v-if="selectedValue" class="flex items-center space-x-2 flex-wrap">
    <select
      v-model="selectedValue"
      id="company_role"
      name="company_role"
      :data-test="`involvement-role${proveForValue ? '-proof' : ''}-select`"
      class="flex-grow pl-2 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    >
      <option
        v-for="option in dropdownOptions"
        :key="option.value"
        :value="option.value"
      >
        {{ option.name }}
      </option>
    </select>
    <template v-if="selectedValue">
      <button
        @click="cancel"
        type="button"
        class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <XMarkIcon class="h-4 w-4" />
      </button>

      <DataVisibilityButton :visibility="visibility" class="inline-flex">
        <template v-slot:button>
          <button
            @click="save(null)"
            :disabled="originatingData"
            type="button"
            :class="
              visibility === 'safezone'
                ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
            "
            class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            :data-test="`involvement-role${proveForValue ? '-proof' : ''}-save`"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            />
            <CheckIcon v-else class="h-4 w-4" />
          </button>
        </template>
      </DataVisibilityButton>
    </template>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useNotificationsStore } from "@/stores/notifications";
import { useProveForValueStore } from "@/stores/proveForValue";
import api from "@/router/api";
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";

const props = defineProps([
  "contentDataField",
  "context",
  "layerType",
  "rawCompanyId",
  "investmentGroupId",
  "availabilityGroupId",
  "availabilityId",
  "clientRole",
  "fetchRequestKey",
  "proveForValue",
  "challengeDataField",
]);
const emit = defineEmits(["cancel", "completed", "unlocked"]);

const notificationsStore = useNotificationsStore();
const proveForValueStore = useProveForValueStore();
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData, changeGroupId } = storeToRefs(changeGroupStore);
const dealBuilderStore = useDealBuilderStore();
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();

const selectedValue = ref(null);

const visibility = computed(() =>
  props.proveForValue ? "public" : "safezone",
);
const defaultThirdPartyOptions = computed(() => {
  return [
    { name: "Capital markets broker", value: "Capital markets broker" },
    { name: "Leasing broker", value: "Leasing broker" },
    { name: "Property manager", value: "Property manager" },
    { name: "Attorney", value: "Attorney" },
    { name: "Architect", value: "Architect" },
  ];
});
const dropdownOptions = computed(() => {
  switch (props.context) {
    case "capital-stack":
    case "controlling-asset":
      if (
        props.contentDataField.decoratingContentType === "OwnershipInterest"
      ) {
        if (props.layerType === "Loan") {
          return [
            {
              name: "Capital markets",
              value: "Originations & Dispositions",
            },
            { name: "Asset manager", value: "Asset manager" },
            { name: "Attorney", value: "Attorney" },
          ];
        } else {
          return [
            {
              name: "Capital markets",
              value: "Acquisitions & Dispositions",
            },
            { name: "Development manager", value: "Development manager" },
            { name: "Construction manager", value: "Construction manager" },
            { name: "Asset manager", value: "Asset manager" },
            { name: "Property manager", value: "Property manager" },
            { name: "Leasing", value: "Leasing" },
            { name: "Attorney", value: "Attorney" },
          ];
        }
      } else {
        return defaultThirdPartyOptions.value;
      }
    case "investment-players":
    case "investment-group-players":
      return [
        { name: "Capital markets broker", value: "Capital markets broker" },
        { name: "Leasing broker", value: "Leasing broker" },
        { name: "Attorney", value: "Attorney" },
      ];
    case "prospect-advisor-partner":
    case "availability-group-prospect-advisor-partner":
    case "investment-group-prospect-advisor-partner": {
      if (props.availabilityId || props.availabilityGroupId) {
        return [
          { name: "Leasing broker", value: "Leasing broker" },
          { name: "Capital markets broker", value: "Capital markets broker" },
          { name: "Attorney", value: "Attorney" },
        ];
      } else {
        return [
          { name: "Equity partner", value: "Equity partner" },
          { name: "Lender", value: "Lender" },
          { name: "Capital markets broker", value: "Capital markets broker" },
          { name: "Leasing broker", value: "Leasing broker" },
          { name: "Attorney", value: "Attorney" },
        ];
      }
    }
    case "hunt-advisor": {
      if (
        props.contentDataField.decoratingContentType === "HuntGeographyIntent"
      ) {
        return defaultThirdPartyOptions.value;
      } else {
        return [
          { name: "Equity partner", value: "Equity partner" },
          { name: "Lender", value: "Lender" },
          { name: "Capital markets broker", value: "Capital markets broker" },
          { name: "Leasing broker", value: "Leasing broker" },
          { name: "Attorney", value: "Attorney" },
        ];
      }
    }
    case "loan-advisor": {
      return [
        { name: "Master Servicer", value: "Master Servicer" },
        { name: "Special Servicer", value: "Special Servicer" },
        { name: "Attorney", value: "Attorney" },
      ];
    }
    case "contacts":
    default:
      return defaultThirdPartyOptions.value;
  }
});

onMounted(() => {
  const defaultOption = _.head(dropdownOptions.value) || {
    name: "Leasing broker",
    value: "Leasing broker",
  };
  selectedValue.value = defaultOption.value;
});

function cancel() {
  emit("cancel");
}

async function afterPersist(json) {
  if (json.data?.proofStatus === "rejected") {
    save(json.data.proofStatus);
  } else if (json.data?.proofStatus === "accepted") {
    notificationsStore.addNotification("proofAccepted");
    emit("unlocked");
  } else if (props.availabilityId || props.availabilityGroupId) {
    await spaceUsageBuilderStore.postEditingPatch(json, props.fetchRequestKey);

    if (json.data.dataField) {
      emit("completed", { dataField: json.data.dataField });
    } else {
      emit("completed");
    }
  } else {
    await dealBuilderStore.postEditingPatch(json, props.fetchRequestKey);

    if (json.data.dataField) {
      emit("completed", { dataField: json.data.dataField });
    } else {
      emit("completed");
    }
  }
}

async function persist(newProofStatus) {
  if (props.proveForValue && !newProofStatus) {
    let payload = {
      fieldValue: selectedValue.value,
      fieldValueType: "string",
      isInner: false,
    };
    if (props.investmentGroupId) {
      payload.investmentGroupId = props.investmentGroupId;
    } else if (props.availabilityGroupId) {
      payload.availabilityGroupId = props.availabilityGroupId;
    }
    const proofResponse = await proveForValueStore.submitProof(
      props.challengeDataField,
      payload,
    );

    return proofResponse;
  } else {
    let payload = {
      fieldValue: selectedValue.value,
      changeGroupId: changeGroupId.value,
    };
    let response;

    if (props.investmentGroupId) {
      payload.investmentGroupId = props.investmentGroupId;
      payload.clientRole = props.clientRole;

      if (_.isNumber(props.rawCompanyId)) {
        payload.rawCompanyId = props.rawCompanyId;
      } else {
        payload.fieldId = props.rawCompanyId.localId;
      }

      response = await api.post(`investment_group_involvement_roles`, payload);
    } else if (props.availabilityGroupId) {
      payload.availabilityGroupId = props.availabilityGroupId;
      payload.clientRole = props.clientRole;

      if (_.isNumber(props.rawCompanyId)) {
        payload.rawCompanyId = props.rawCompanyId;
      } else {
        payload.fieldId = props.rawCompanyId.localId;
      }

      response = await api.post(
        `availability_group_involvement_roles`,
        payload,
      );
    } else {
      response = await api.post(
        `involvement_roles/${props.contentDataField.fieldContentType}/${props.contentDataField.fieldContentId}`,
        payload,
      );
    }

    if (newProofStatus === "rejected") {
      if (response.data.dataField) {
        const declassifyPayload = {
          id: response.data.dataField.localId,
        };

        const declassifiedResponse = await api.post(
          `declassify_datapoint`,
          declassifyPayload,
        );
        response.data.dataField = declassifiedResponse.data;
      } else if (response.data.dataFields) {
        const declassifyPayload = {
          ids: response.data.dataFields.map((field) => field.localId),
        };
        await api.post(`declassify_datapoints`, declassifyPayload);
      }

      notificationsStore.addNotification("proofRejected");
    }

    return response;
  }
}

function save(newProofStatus = null) {
  const apiRequestFunc = () => persist(newProofStatus);
  const successCallback = (json) => afterPersist(json);
  const failureCallback = () => cancel();

  return changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
</script>
