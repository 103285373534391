<template>
  <tr
    v-if="huntId"
    v-observe-visibility="{ callback: fetchHuntInvolvementField, once: true }"
    @mouseenter="handleHover"
    @mouseleave="clearHover"
    class=""
  >
    <td
      v-if="huntOuterDataField"
      class="align-top whitespace-nowrap py-2 pr-3 pl-4"
    >
      <div class="min-w-[200px] flex items-center">
        <DataField
          v-if="huntInvolvementDataField"
          :data-field="huntInvolvementDataField"
          text-classes="text-sm font-medium max-w-[325px]"
          :text-wrap="true"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="refetchAll"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
          @set-proof="setProof"
        />
        <span v-else class="text-sm text-gray-500">Undisclosed Company</span>
      </div>
      <div v-if="huntOuterDataField" class="flex items-center">
        <DataField
          :data-field="huntOuterDataField"
          text-classes="text-sm font-medium"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="refetchAll"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
          @set-proof="setProof"
        />
      </div>
    </td>
    <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <dl
        class="grid gap-3"
        :class="
          workspaceLayout === 'sideBySide' ? 'grid-cols-1' : 'grid-cols-2'
        "
      >
        <div class="col-span-1">
          <dt class="text-sm font-medium text-gray-500">Hunter Contacts</dt>
          <dd class="mt-1">
            <ContentContacts
              v-if="huntInvolvementDataField"
              :data-field="huntInvolvementDataField"
              :content-data-field="huntInvolvementDataField"
              context="hunt-table-row"
            />
          </dd>
        </div>
        <AdvisorsDetail
          :data-field="huntOuterDataField"
          :fetch-milliseconds="asOfMilliseconds"
          clientRole="hunter"
          context="hunt-advisor"
        />
      </dl>
    </td>
    <td
      v-if="huntOuterDataField"
      class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500"
    >
      <div class="flex flex-col space-y-1">
        <DataField
          v-for="marketField in targetMarketDataFields"
          :key="marketField.localId"
          :data-field="marketField"
          text-classes="text-sm font-medium"
          text-styles=""
          @completed="refetchAll"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
        />
      </div>
    </td>
    <td class="align-top whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <div class="flex flex-col">
        <OccupierNeeds
          v-if="occupier"
          :data-field="huntOuterDataField"
          :content-data-field="huntOuterDataField"
          context="hunt-table-row"
        />
        <HuntInvestmentFilters
          v-if="investor"
          :data-field="huntOuterDataField"
          context="hunt-table-row"
        />
      </div>
    </td>
  </tr>
</template>

<script setup>
import AdvisorsDetail from "@/components/deal-builder/AdvisorsDetail.vue";
import CompanyContactAutocomplete from "@/components/crowdsourcing/CompanyContactAutocomplete.vue";
import ContentContacts from "@/components/crowdsourcing/ContentContacts.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import HuntInvestmentFilters from "@/components/crowdsourcing/HuntInvestmentFilters.vue";
import OccupierNeeds from "@/components/crowdsourcing/OccupierNeeds.vue";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useProveForValueStore } from "@/stores/proveForValue";
import { useMainMapStore } from "@/stores/mainMap";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["huntOuterDataField"]);
const analyzePanelStore = useAnalyzePanelStore();
const { analyzeHuntFields, fetchedHuntKeys, hoveringTableRowContent } =
  storeToRefs(analyzePanelStore);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const proveForValueStore = useProveForValueStore();
const mapStore = useMainMapStore();

const huntId = computed(() => props.huntOuterDataField?.fieldContentId);
const investor = computed(
  () => props.huntOuterDataField?.fieldContent?.hasInvestmentCriteria,
);
const occupier = computed(
  () => props.huntOuterDataField?.fieldContent?.hasSpaceRequirements,
);
const huntCompanyKey = computed(() => {
  return `Hunt${huntId.value}SourceCompanyInvolvement`;
});
const matchingSourceCompanyKeys = computed(() => {
  return fetchedHuntKeys.value.filter((key) => {
    return _.includes(key, huntCompanyKey.value);
  });
});
const huntInvolvementDataField = computed(() => {
  if (huntCompanyKey.value) {
    const matchingFields = _.filter(
      analyzeHuntFields.value,
      function (dataField, key) {
        return _.includes(matchingSourceCompanyKeys.value, key);
      },
    );
    return _.head(matchingFields);
  } else {
    return null;
  }
});
const targetMarketKey = computed(() => {
  return `Hunt${huntId.value}HuntGeographyIntent`;
});
const matchingTargetMarketKeys = computed(() => {
  return fetchedHuntKeys.value.filter((key) => {
    return _.includes(key, targetMarketKey.value);
  });
});
const targetMarketDataFields = computed(() => {
  if (targetMarketKey.value) {
    return _.filter(analyzeHuntFields.value, function (dataField, key) {
      return _.includes(matchingTargetMarketKeys.value, key);
    });
  } else {
    return [];
  }
});

async function fetchHuntInvolvementField() {
  if (huntId.value) {
    await analyzePanelStore.fetchHuntDataField(huntId.value);
    fetchHuntGeographyIntents();
  }
}

function fetchHuntGeographyIntents() {
  analyzePanelStore.fetchHuntGeographyIntents(huntId.value, "huntFields");
}

function handleHover() {
  displayMarketPolygons();
}

function clearHover() {
  hoveringTableRowContent.value = null;
}

function displayMarketPolygons() {
  if (props.huntOuterDataField) {
    hoveringTableRowContent.value = props.huntOuterDataField;
  }
}

function clearRow() {
  console.log("clear row");
}

function refetchAll() {
  console.log("hunt row refetch all");
  clearRow();
  if (props.huntOuterDataField) {
    delete analyzeHuntFields.value[`Hunt${huntId.value}`];
    fetchHuntInvolvementField();
  }
  mapStore.tapView();
}

const availableProps = computed(() => {
  const inputKeyField = props.huntOuterDataField;

  return [
    {
      fieldName: "Hunt",
      inputKey: `${inputKeyField.decoratingContentType}-${inputKeyField.decoratingContentId}-${inputKeyField.fieldContentType}-${inputKeyField.fieldContentId}`,
      fetchMilliseconds: asOfMilliseconds.value,
    },
  ];
});

function setProof(fieldName) {
  const matchedProps = _.find(availableProps.value, { fieldName });

  if (matchedProps) {
    proveForValueStore.setProofComponent(
      CompanyContactAutocomplete,
      matchedProps,
    );
  }
}
</script>
