<template>
  <div class="p-2 space-y-1">
    <div class="flex justify-between">
      <div>
        <div class="text-sm text-gray-900 font-semibold">
          {{ payload.title || `Payload ${payload.id}` }}
        </div>
        <div class="text-sm text-gray-700">
          {{ payload.geography?.name || payload.geography?.city }}
        </div>
        <div class="text-sm text-gray-700">
          {{ payload.uses.map((use) => use.name).join(", ") }}
        </div>
      </div>
      <div>
        <button
          v-if="isAdmin && !finalized"
          @click="easyDataInputAdminStore.removePayload(payload.id)"
          type="button"
          v-tooltip="'Remove payload'"
          class="inline-flex items-center text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          <XMarkIcon class="h-6 w-6" />
        </button>
      </div>
    </div>
    <ul role="list" class="grid grid-cols-2 gap-2">
      <DataInputJobExistingPayloadItem
        v-for="item in payload.items"
        :key="item.id"
        :item="item"
        @select-item="emit('select-item', item)"
      />
      <DataInputJobPayloadItem
        v-for="item in insertedDraftItems"
        :key="item.id"
        :item="item"
        class="col-span-2"
      />
    </ul>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { useEasyDataInputAdminStore } from "@/stores/easyDataInputAdmin";
import { useUserStore } from "@/stores/user";
import DataInputJobExistingPayloadItem from "@/views/admin/DataInputJobExistingPayloadItem.vue";
import DataInputJobPayloadItem from "@/views/admin/DataInputJobPayloadItem.vue";
import _ from "lodash";

const props = defineProps(["payload"]);
const emit = defineEmits(["select-item"]);

const userStore = useUserStore();
const { isAdmin } = storeToRefs(userStore);
const easyDataInputAdminStore = useEasyDataInputAdminStore();
const { detailedSelectedJobCRUD, detailedSelectedJob } = storeToRefs(
  easyDataInputAdminStore,
);

const finalized = computed(() => {
  const status =
    detailedSelectedJob.value?.packagingStatus ||
    props.payload?.packagingStatus;

  return status === "finalized";
});

const payloadItems = computed({
  get() {
    return detailedSelectedJobCRUD.value?.payloadItems;
  },
  set(newArr) {
    detailedSelectedJobCRUD.value.payloadItems = newArr;
  },
});

const insertedDraftItems = computed(() => {
  if (isAdmin.value) {
    return payloadItems.value.filter(
      ({ id, payloadId }) =>
        payloadId === props.payload.id && _.includes(id, "temp"),
    );
  } else {
    return [];
  }
});
</script>
