import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { computed, ref, watch, markRaw } from "vue";
import { useRoute } from "vue-router";
import { useModalStore } from "@/stores/modal";
import { useMainMapStore } from "@/stores/mainMap";
import { useUnlockerStore } from "@/stores/unlocker";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import UnlicensedContentWarning from "@/components/users/subscribe-prompts/UnlicensedContentWarning.vue";
import unlockableInnerContent from "@/components/crowdsourcing/unlockableInnerContent";
import api from "@/router/api";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import selfSelected from "@/components/company-detail/detailPageSelfSelected";
import stateAbbreviationsUS from "@/assets/stateAbbreviationsUS";
import {
  propertyIdFor,
  investmentFor,
  assetMetaFor,
  matchingInvolvement,
  isHunt,
  isHuntAdvisory,
} from "@/components/company-detail/companyInvolvementGroups";
import _ from "lodash";

export const useCompanyDetailStore = defineStore("companyDetail", () => {
  const companyDetailSelected = ref(null);
  const companyAllFetchedFields = ref([]);
  const companyPagy = ref(null);
  const fetchedPropertyDataFields = ref([]);
  const companyFetchedLocationDataFields = ref([]);
  const companyFetchedPolygonDataFields = ref([]);
  const companyPatchableTimelineEvents = ref([]);
  const selectableContent = ref(null);
  const editingMode = ref(false);
  const lockedForEditing = ref(false);
  const companyActionTrigger = ref(null);
  const companyMapFitted = ref(false);
  const hovering = ref(null);
  const fetchingCompanyData = ref(false);
  const upgradeInProgress = ref(false);
  const debouncing = ref(false);
  const fetchRequests = ref([]);
  const { windowWidth, windowHeight, workspaceLayout, workspaceResized } =
    storeToRefs(useWorkspaceLayoutStore());
  const { map, mapBoundaryMeta, expandedMap, propertyMarkerPulseId } =
    storeToRefs(useMainMapStore());
  const { resetRequired, upgradeSuccessful } = storeToRefs(useUnlockerStore());
  const { modalPayload } = storeToRefs(useModalStore());
  const { asOfMilliseconds } = storeToRefs(useTimeTravelStore());
  const { crowdsourcedChangeGroup } = storeToRefs(
    useCrowdsourcedChangeGroupStore(),
  );

  const applyEditingLock = computed(() => false);
  const companyDataField = computed(() => {
    return _.find(companyAllFetchedFields.value, {
      decoratingContentType: null,
      fieldContentType: "Company",
    });
  });
  const companySelectedRecordDataField = computed(() => {
    const recordType = _.get(
      companyDetailSelected.value,
      "fieldContentType",
      "Company",
    );

    if (recordType === "Company" && companyDataField.value) {
      return companyDataField.value;
    } else {
      return companyDetailSelected.value;
    }
  });

  const route = useRoute();
  const companyIdParam = computed(() => {
    return _.get(route, "params.companyId", null);
  });
  const companyInvolvements = computed(() => {
    const raw = companyAllFetchedFields.value.filter((dataField) => {
      return (
        dataField.fieldContentType === "CompanyInvolvement" ||
        (dataField.fieldContentType === "Hunt" &&
          dataField.decoratingContentType === "CompanyInvolvement")
      );
    });

    return _.orderBy(
      raw,
      ["fieldContentSubType", "decoratingContentType"],
      ["desc", "desc"],
    );
  });
  const companyInvolvementsSize = computed(
    () => companyInvolvements.value?.length,
  );
  const zoom = computed(() => mapBoundaryMeta.value.zoom);
  const centerLatLng = computed(() => mapBoundaryMeta.value.centerLatLng);
  const centerLatLngSum = computed(() => {
    if (centerLatLng.value) {
      return _.round(centerLatLng.value[0] + centerLatLng.value[1], 13);
    } else {
      return null;
    }
  });

  watch(resetRequired, () => {
    if (upgradeInProgress.value) {
      resetRequired.value = false;
      upgradeInProgress.value = false;
    }
  });
  watch(asOfMilliseconds, async () => {
    if (companyIdParam.value) {
      console.log("as of watcher");
      refetchDetails();
    }
  });
  watch(zoom, (val) => {
    if (val && companyIdParam.value) {
      console.log("zoom watcher");
      refetchDetails();
    }
  });
  watch(centerLatLngSum, (val) => {
    if (val && companyIdParam.value) {
      console.log("lat/lng watcher");
      refetchDetails();
    }
  });
  watch(companyIdParam, (val, oldVal) => {
    if (val && val !== oldVal) {
      clearCompanyData();
      refetchDetails();
    }
  });
  watch(expandedMap, () => {
    resizeInvolvementsContainer();
  });
  watch(workspaceLayout, () => {
    resizeInvolvementsContainer();
  });
  watch(windowHeight, () => {
    resizeInvolvementsContainer();
  });
  watch(windowWidth, () => {
    resizeInvolvementsContainer();
  });
  watch(workspaceResized, () => {
    setTimeout(function () {
      resizeInvolvementsContainer();
    }, 100);
  });

  // FETCH MANAGEMENT

  const fetchedKeys = computed(() =>
    fetchRequests.value.map((request) => request.key),
  );

  function alreadyFetchedFieldsFor(fetchRequestKey) {
    const request = _.find(fetchRequests.value, { key: fetchRequestKey });

    return companyAllFetchedFields.value.filter((field) =>
      _.includes(request?.fieldIds || [], field.localId),
    );
  }
  function alreadyFetched(fetchRequestKey) {
    return _.includes(fetchedKeys.value, fetchRequestKey);
  }
  function registerFetchRequest(dataFields, fetchRequestKey) {
    const payload = {
      key: fetchRequestKey,
      fieldIds: dataFields.map((field) => field.localId),
    };

    fetchRequests.value = _.unionBy([payload], fetchRequests.value, "key");
  }
  function updateFetchRequests(dataFields, fetchRequestKey) {
    const stillPresentFields = alreadyFetchedFieldsFor(fetchRequestKey);
    // console.log(
    //   "still present",
    //   fetchRequestKey,
    //   stillPresentFields.map((field) => field.localId)
    // );
    const updatedFields = _.unionBy(dataFields, stillPresentFields, "localId");
    // console.log(
    //   "updated",
    //   fetchRequestKey,
    //   updatedFields.map((field) => field.localId)
    // );

    registerFetchRequest(updatedFields, fetchRequestKey);
  }
  function dropFetchRequest(dropKey) {
    fetchRequests.value = fetchRequests.value.filter(
      ({ key }) => key !== dropKey,
    );
  }
  function cleanUpRequests() {
    fetchRequests.value.forEach(({ key }) => updateFetchRequests([], key));
  }

  //  END FETCH MANAGEMENT

  const resizeInvolvementsContainer = _.debounce(setContainerHeight, 100);

  function setContainerHeight() {
    if (companyIdParam.value) {
      console.log("resize container");
      const workspaceSplitterEl = document
        .getElementById("workspace-shell-split-gutter")
        ?.getBoundingClientRect()?.height;
      const secondaryPanelContainer = document
        .getElementById("lists-panel-container")
        ?.getBoundingClientRect()?.height;
      const splitterEl = document
        .getElementById("company-shell-split-gutter")
        ?.getBoundingClientRect()?.height;
      const controlBarEl = document
        .getElementById("company-control-bar")
        ?.getBoundingClientRect()?.height;
      const timelineEl = document
        .getElementById("company-shell-timeline")
        ?.getBoundingClientRect()?.height;
      const activeEasyDataPayloadItemBannerEl = document
        .getElementById("easy-data-active-payload-item-banner")
        ?.getBoundingClientRect()?.height;
      const welcomeBannerEl = document
        .getElementById("welcome-banner")
        ?.getBoundingClientRect()?.height;
      const emailBannerEl = document
        .getElementById("unverified-email-banner")
        ?.getBoundingClientRect()?.height;
      const headerEl = document
        .getElementById("shell-header-banner")
        ?.getBoundingClientRect()?.height;
      const mapEl = expandedMap.value
        ? document.getElementById("property-map")?.getBoundingClientRect()
            ?.height
        : 0;
      const allHeights = _.compact([
        workspaceLayout.value === "topAndBottom" ? workspaceSplitterEl : 0,
        workspaceLayout.value === "topAndBottom" ? secondaryPanelContainer : 0,
        splitterEl,
        controlBarEl,
        timelineEl,
        activeEasyDataPayloadItemBannerEl,
        welcomeBannerEl,
        emailBannerEl,
        headerEl,
        mapEl,
      ]);
      const elsHeight = _.sum(allHeights);
      const body = document.body,
        html = document.documentElement;
      const pageHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      const calculatedHeight = (pageHeight - elsHeight) * 0.9;
      const involvementsContainerEl = document.getElementById(
        "involvements-container",
      );

      if (elsHeight && involvementsContainerEl) {
        involvementsContainerEl.style.height = `${_.round(calculatedHeight)}px`;
      }
    }
  }

  async function refetchDetails() {
    debouncing.value = true;
    debouncedCompanyDataFetch();
  }

  async function removeUnusedData() {
    return new Promise((resolve) => {
      companyAllFetchedFields.value = [];
      companyPagy.value = null;
      resolve();
    });
  }

  async function refetchDataField(dataFieldLocalId, template = "visible") {
    api
      .get(`data_field_by_id/${dataFieldLocalId}?template=${template}`)
      .then((json) => {
        interceptablePatch([json.data]);
      });
  }

  async function fetchPropertyDataField(propertyId) {
    const response = await api.get(`properties/${propertyId}`);

    patchPropertyDataFields(response.data);
  }
  async function fetchLoanCollateralFor(involvementDataField) {
    const loanId = assetMetaFor(involvementDataField)?.id;

    if (loanId) {
      const response = await api.get(`loan_collateral/${loanId}`);

      return response.data;
    } else {
      return null;
    }
  }
  async function fetchCompanyDataField(id) {
    if (id) {
      const response = await api.get(`companies/${_.toNumber(id)}`);

      return response.data;
    }
  }
  function fetchParams(payload = {}) {
    if (map.value) {
      const bounds = map.value.getBounds();
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();
      const northeastLatLng = [northeast.lat, northeast.lng];
      const southwestLatLng = [southwest.lat, southwest.lng];
      payload.southwest = southwestLatLng;
      payload.northeast = northeastLatLng;
    }

    return payload;
  }
  async function fetchInvolvements() {
    if (companyIdParam.value) {
      console.log("fetch involvements");
      const response = await api.patch(
        `company_involvements/${companyIdParam.value}?as_of=${asOfMilliseconds.value}`,
        fetchParams(),
      );

      return response.data;
    }
  }
  function cleanUrl(url) {
    return url.replace("/api/v1/", "");
  }
  async function loadInvolvements({ state }) {
    // console.log("pagination attempt");

    if (companyPagy.value?.next) {
      const endpoint = cleanUrl(companyPagy.value?.next_url);
      try {
        api.patch(endpoint, fetchParams()).then((json) => {
          const { data, pagy } = json.data;

          companyPagy.value = pagy;
          interceptablePatch(data);
          if (data.length < 3) {
            state.complete();
            // console.log("pagination fully complete");
          } else {
            state.loaded();
          }
        });
      } catch (error) {
        state.error();
      }
    } else {
      // console.log("pagination complete without ever fetching");
      state.complete();
    }
  }

  function tryRefetchLater(timeout = 1000) {
    setTimeout(() => {
      refetchDetails();
    }, timeout);
  }

  const debouncedCompanyDataFetch = _.debounce(function () {
    console.log("debounced company fetch");
    fetchCompanyData();
  }, 3000);

  async function fetchCompanyData(outerOnly = false) {
    if (!companyIdParam.value) return;
    if (fetchingCompanyData.value) {
      tryRefetchLater();
      return;
    }

    fetchingCompanyData.value = true;
    debouncing.value = false;

    await removeUnusedData();

    let fetchedInvolvements = [];

    const outerField = await fetchCompanyDataField(companyIdParam.value);

    if (!outerOnly) {
      // FETCH ALL OTHER FIELDS
      fetchedInvolvements = await fetchInvolvements();
    }
    const combined = _.unionBy(
      fetchedInvolvements.data,
      [outerField],
      "localId",
    );
    interceptablePatch(combined);
    companyPagy.value = fetchedInvolvements.pagy;
    fetchingCompanyData.value = false;

    setTimeout(() => {
      resizeInvolvementsContainer();
    }, 250);
  }

  function interceptableField(dataField) {
    return _.get(dataField, "meteringStatus") === "prompt_to_subscribe";
  }
  function interceptablePatch(dataFields) {
    if (_.some(dataFields, interceptableField)) {
      promptToSubscribe();
    } else {
      patchFetchedFields(dataFields);
    }
  }
  function patchFetchedFields(newFields) {
    return new Promise((resolve) => {
      dropInterceptFields();
      companyAllFetchedFields.value = _.unionBy(
        newFields,
        companyAllFetchedFields.value,
        "localId",
      );
      dropDeprecatedFields();

      if (_.some(newFields, unlockableInnerContent)) {
        debouncedUnlockWarn();
      }

      resolve();
    });
  }
  function patchPropertyDataFields(newFields) {
    console.log("patch property fields", newFields);
    fetchedPropertyDataFields.value = _.unionBy(
      _.isArray(newFields) ? newFields : [newFields],
      fetchedPropertyDataFields.value,
      "localId",
    );
  }
  function patchLocationDataFields(newFields) {
    companyFetchedLocationDataFields.value = _.unionBy(
      _.isArray(newFields) ? newFields : [newFields],
      companyFetchedLocationDataFields.value,
      "localId",
    );
  }
  function patchPolygonDataFields(newFields) {
    companyFetchedPolygonDataFields.value = _.unionBy(
      _.isArray(newFields) ? newFields : [newFields],
      companyFetchedPolygonDataFields.value,
      "localId",
    );
  }
  async function postEditingPatch(json, refetch = false) {
    if (!companyIdParam.value) return;

    let newData = null;
    let updatedContent = null;
    if (json.data.dataField) newData = [json.data.dataField];
    else if (json.data.dataFields) newData = json.data.dataFields;
    if (json.data.updatedContent) updatedContent = json.data.updatedContent;

    if (json.data.timelineEvents) {
      console.log("company store received timeline events");
      companyPatchableTimelineEvents.value = json.data.timelineEvents;
    }

    if (newData && updatedContent) {
      await patchFetchedFields(newData);
      patchFieldContent(updatedContent);
    } else if (newData) patchFetchedFields(newData);
    else if (updatedContent) patchFieldContent(updatedContent);

    if (refetch && newData) {
      let refetched = false;
      for (const dataField of newData) {
        if (dataField.fieldContentType === "CompanyInvolvement") {
          const involvementField = involvementFieldByInvolvementId(
            dataField.fieldContentId,
          );

          if (involvementField && !refetched) {
            await refetchDataField(involvementField.localId, "details_page");
            refetched = true;
          }
        } else if (dataField.decoratingContentType === "CompanyInvolvement") {
          const involvementField = involvementFieldByInvolvementId(
            dataField.decoratingContentId,
          );

          if (involvementField && !refetched) {
            await refetchDataField(involvementField.localId, "details_page");
            refetched = true;
          }
        } else if (
          _.includes(
            ["Hunt", "HuntGeographyIntent"],
            dataField.decoratingContentType,
          )
        ) {
          const fetchField = _.find(
            companyAllFetchedFields.value,
            function (field) {
              if (isHunt(field)) {
                return (
                  field.fieldContentType === "Hunt" &&
                  field.fieldContentId == dataField.decoratingContentId
                );
              } else if (isHuntAdvisory(field)) {
                return (
                  field.decoratingContentType ===
                    dataField.decoratingContentType &&
                  field.decoratingContentId == dataField.decoratingContentId
                );
              } else {
                return false;
              }
            },
          );

          if (fetchField && !refetched) {
            await refetchDataField(fetchField.localId, "details_page");
            refetched = true;
          }
        }
      }

      companyDetailSelected.value = null;
    }
  }
  function patchFieldContent(updatedContent = []) {
    for (const content of updatedContent) {
      let matchedDataFields = _.filter(
        companyAllFetchedFields.value,
        function (dataField) {
          return (
            dataField.fieldContentType === content.recordType &&
            dataField.fieldContentId == content.id
          );
        },
      );
      let isSelected = selfSelected(
        content,
        companySelectedRecordDataField.value,
      );

      if (matchedDataFields.length > 0) {
        for (const dataField of matchedDataFields) {
          dataField.fieldContent = content;
        }
        patchFetchedFields(matchedDataFields);
      }
      if (isSelected) {
        companySelectedRecordDataField.value.fieldContent = content;
      }
    }
  }
  function dropInterceptFields() {
    const strippedFields = companyAllFetchedFields.value.filter((dataField) => {
      return !dataField.meteringStatus;
    });
    companyAllFetchedFields.value = strippedFields;
    cleanUpRequests();
  }
  function dropDeprecatedFields() {
    const strippedFields = companyAllFetchedFields.value.filter((dataField) => {
      return !dataField.deprecatedAt && !dataField.dismissed;
    });
    companyAllFetchedFields.value = strippedFields;
    cleanUpRequests();
  }
  const debouncedUnlockWarn = _.debounce(function () {
    unlockWarn();
  }, 3000);
  function unlockWarn() {
    return new Promise((resolve) => {
      if (!modalPayload.value) {
        modalPayload.value = {
          size: "base",
          theme: "light",
          component: markRaw(UnlicensedContentWarning),
          props: {},
        };
        resolve();
      } else {
        resolve();
      }
    });
  }
  function promptToSubscribe() {
    return new Promise((resolve) => {
      if (!modalPayload.value) {
        upgradeInProgress.value = true;
        const successCallback = () => {
          if (upgradeSuccessful.value) {
            console.log("user subscribed");
            upgradeInProgress.value = false;
            upgradeSuccessful.value = false;
            refetchDetails();
            resolve();
          }
        };

        subscribeInterceptor({
          apiRequestFunc: null,
          successCallback,
          modalPayloadRef: modalPayload,
          upgradeSuccessfulRef: upgradeSuccessful,
          afterSubscribe: "successCallback",
          promptReason: "dataUsage",
          context: "licensing",
        });
      } else {
        resolve();
      }
    });
  }
  function clearCompanyData() {
    companyAllFetchedFields.value = [];
    fetchRequests.value = [];
    companyPagy.value = null;
    fetchedPropertyDataFields.value = [];
    companyFetchedLocationDataFields.value = [];
    companyFetchedPolygonDataFields.value = [];
    crowdsourcedChangeGroup.value = null;
    companyDetailSelected.value = null;
    companyMapFitted.value = false;
    companyActionTrigger.value = null;
    removeUnusedData();
  }

  async function findHoveringInvolvement(dataField, select = false) {
    let involvementField = null;
    if (dataField.fieldContentType === "Investment") {
      const investmentId = dataField.fieldContentId;
      involvementField = _.find(companyInvolvements.value, function (df) {
        return investmentFor(df)?.id === investmentId;
      });
    } else if (dataField.fieldContentType === "SpaceAvailability") {
      const availabilityId = dataField.fieldContentId;
      involvementField = _.find(companyInvolvements.value, function (df) {
        return df.fieldContent?.availabilityId === availabilityId;
      });
    } else if (dataField.decoratingContentType === "CompanyInvolvement") {
      const involvementId = dataField.decoratingContentId;
      involvementField = _.find(companyInvolvements.value, {
        fieldContentId: involvementId,
      });
    } else if (dataField.decoratingContentType === "SpaceUsage") {
      const spaceUsageId = dataField.decoratingContentId;
      involvementField = _.find(companyInvolvements.value, function (df) {
        return df.fieldContent?.spaceUsageId === spaceUsageId;
      });
    } else if (
      _.includes(
        ["Hunt", "HuntGeographyIntent"],
        dataField.decoratingContentType,
      )
    ) {
      involvementField = _.find(
        companyAllFetchedFields.value,
        function (field) {
          if (isHunt(field)) {
            return (
              field.fieldContentType === "Hunt" &&
              field.fieldContentId == dataField.decoratingContentId
            );
          } else if (isHuntAdvisory(field)) {
            return (
              field.decoratingContentType === dataField.decoratingContentType &&
              field.decoratingContentId == dataField.decoratingContentId
            );
          } else {
            return false;
          }
        },
      );
    } else {
      console.log("unknown", dataField);
    }

    if (involvementField) {
      if (propertyIdFor(involvementField))
        await fetchPropertyDataField(propertyIdFor(involvementField));

      if (select) {
        toggleSelected(involvementField);
      } else {
        hover(involvementField);
      }
    }
  }
  function toggleSelected(dataField) {
    if (matchingInvolvement(dataField, companyDetailSelected.value))
      companyDetailSelected.value = null;
    else companyDetailSelected.value = dataField;

    editingMode.value = false;
  }
  function hover(dataField) {
    stopHovering.cancel();
    hovering.value = dataField;
    pulsePropertyFor(dataField);
  }
  const stopHovering = _.debounce(function () {
    setTimeout(() => {
      hovering.value = null;
    }, 50);
  }, 250);

  function involvementFieldByInvolvementId(involvementId) {
    return _.find(companyAllFetchedFields.value, function (dataField) {
      if (isHunt(dataField)) {
        return (
          dataField.decoratingContentType === "CompanyInvolvement" &&
          dataField.decoratingContentId == involvementId
        );
      } else {
        return (
          dataField.fieldContentType === "CompanyInvolvement" &&
          dataField.fieldContentId == involvementId
        );
      }
    });
  }
  function pulsePropertyFor(dataField) {
    const propertyId = propertyIdFor(dataField);

    if (propertyId) propertyMarkerPulseId.value = propertyId;
  }
  function propertyDataFieldFor(involvementDataField) {
    return _.find(fetchedPropertyDataFields.value, {
      fieldContentId: propertyIdFor(involvementDataField),
    });
  }
  function propertyNameFor(involvementDataField) {
    return (
      _.get(propertyDataFieldFor(involvementDataField), "fieldContent.name") ||
      "Unlock"
    );
  }
  function propertyLocationFor(involvementDataField) {
    const city = _.get(
      propertyDataFieldFor(involvementDataField),
      "fieldContent.locationDataField.fieldContent.city",
    );
    const state = _.get(
      propertyDataFieldFor(involvementDataField),
      "fieldContent.locationDataField.fieldContent.state",
    );
    const abbreviatedState = stateAbbreviationsUS[state];
    const combined =
      city && state ? `${city}, ${abbreviatedState || state}` : "to reveal";

    return combined;
  }

  watch(applyEditingLock, () => {
    if (applyEditingLock.value) lockedForEditing.value = true;
    else lockedForEditing.value = false;
  });

  return {
    editingMode,
    selectableContent,
    lockedForEditing,
    companyActionTrigger,
    companyDetailSelected,
    companySelectedRecordDataField,
    companyDataField,
    companyIdParam,
    companyAllFetchedFields,
    companyPagy,
    companyPatchableTimelineEvents,
    companyInvolvements,
    companyInvolvementsSize,
    companyMapFitted,
    hovering,
    debouncing,
    fetchingCompanyData,
    fetchedPropertyDataFields,
    companyFetchedLocationDataFields,
    companyFetchedPolygonDataFields,
    fetchPropertyDataField,
    fetchCompanyDataField,
    fetchCompanyData,
    fetchLoanCollateralFor,
    removeUnusedData,
    refetchDetails,
    refetchDataField,
    loadInvolvements,
    postEditingPatch,
    patchPropertyDataFields,
    patchLocationDataFields,
    patchPolygonDataFields,
    findHoveringInvolvement,
    clearCompanyData,
    propertyDataFieldFor,
    propertyNameFor,
    propertyLocationFor,
    toggleSelected,
    hover,
    alreadyFetched,
    alreadyFetchedFieldsFor,
    registerFetchRequest,
    dropFetchRequest,
    stopHovering,
    resizeInvolvementsContainer,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCompanyDetailStore, import.meta.hot),
  );
}
