<template>
  <div
    class="h-full flex flex-col overflow-hidden bg-gray-900"
    data-test="card-shell"
  >
    <Disclosure as="nav" class="bg-gray-900">
      <div class="max-w-7xl mx-auto px-6">
        <div class="flex h-16 items-center justify-between">
          <HomeLogo />
          <div v-if="!activeEasyDataInputPayloadItem" class="flex items-center">
            <DataInputEasyButton />
          </div>
          <div class="flex items-center">
            <UserMenu v-if="signedIn" />
            <GuestMenu v-else />
          </div>
        </div>
      </div>
    </Disclosure>

    <ContentHighlight
      v-if="selectedMobileTab === 'Details' && mobileHighlightDataField"
      :data-field="mobileHighlightDataField"
      context="shell"
    />
    <StackOfCards v-else-if="selectedMobileTab === 'News'" context="shell" />
    <TaskShell v-else-if="selectedMobileTab === 'Tasks'" />
  </div>
</template>

<script setup>
import StackOfCards from "@/components/collectible-cards/StackOfCards.vue";
import TaskShell from "@/components/tasks/TaskShell.vue";
import UserMenu from "@/components/users/UserMenu.vue";
import GuestMenu from "@/components/users/GuestMenu.vue";
import ContentHighlight from "@/components/collectible-cards/ContentHighlight.vue";
import DataInputEasyButton from "@/components/DataInputEasyButton.vue";
import HomeLogo from "@/components/HomeLogo.vue";
import { Disclosure } from "@headlessui/vue";
import { useUserStore } from "@/stores/user";
import { useMobileNavigationStore } from "@/stores/mobileNavigation";
import { useCollectibleCardsChannelStore } from "@/stores/collectibleCardsChannel";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useNotificationsStore } from "@/stores/notifications";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/router/api";
import createChannel from "@/channels/channel";
import _ from "lodash";

const userStore = useUserStore();
const {
  currentUser,
  signedIn,
  activeEasyDataInputPayloadItem,
  availableCollectibleCardCount,
} = storeToRefs(userStore);
const notificationsStore = useNotificationsStore();
const mobileNavigationStore = useMobileNavigationStore();
const { selectedMobileTab } = storeToRefs(mobileNavigationStore);
const collectibleCardsChannelStore = useCollectibleCardsChannelStore();
const { CollectibleCardsChannel, collectibleCardsChannelDataQueue } =
  storeToRefs(collectibleCardsChannelStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyIdParam } = storeToRefs(propertyDiagramStore);
const companyDetailStore = useCompanyDetailStore();
const { companyIdParam } = storeToRefs(companyDetailStore);
const contactDetailStore = useContactDetailStore();
const { contactIdParam } = storeToRefs(contactDetailStore);

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);

const mobileHighlightDataField = ref(null);

const detailPage = computed(
  () => propertyIdParam.value || companyIdParam.value || contactIdParam.value,
);

watch(collectibleCardsChannelDataQueue, () => {
  const data = _.last(collectibleCardsChannelDataQueue.value);
  availableCollectibleCardCount.value = data.count;
});

onMounted(async () => {
  if (signedIn.value && _.get(query.value, "easyDataInputJobInvitationToken")) {
    await fetchEasyDataInputJobInvitation();
  }

  if (signedIn.value && !CollectibleCardsChannel.value) {
    CollectibleCardsChannel.value = createChannel(
      {
        channel: "CollectibleCardsChannel",
        userId: currentUser.value.id,
      },
      {
        connected() {},
        received(data) {
          collectibleCardsChannelStore.pushAndTrim(data);
        },
      },
    );
  }
  if (detailPage.value) {
    selectedMobileTab.value = "Details";
    fetchMobileHighlightDataField();
  } else if (
    signedIn.value &&
    _.get(query.value, "horizontalTab") === "Authenticate"
  ) {
    selectedMobileTab.value = "News";
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Me",
        verticalTab: "News",
      },
    });
  } else if (
    !signedIn.value &&
    _.get(query.value, "horizontalTab") === "Authenticate" &&
    _.includes(["Register", "SignIn"], query.value.verticalTab)
  ) {
    router.push({
      name: query.value.verticalTab,
      query: {
        teamInvitationToken: route.query.teamInvitationToken,
        userInvitationToken: route.query.userInvitationToken,
        easyDataInputJobInvitationToken:
          route.query.easyDataInputJobInvitationToken,
      },
    });
  }
});

async function fetchMobileHighlightDataField() {
  let contentType = null;

  if (propertyIdParam.value) {
    contentType = "Property";
  } else if (companyIdParam.value) {
    contentType = "Company";
  } else if (contactIdParam.value) {
    contentType = "Contact";
  }

  const response = await api.get(
    `mobile_highlight_outer_data_fields/${contentType}/${
      propertyIdParam.value || companyIdParam.value || contactIdParam.value
    }`,
  );

  if (response?.data) {
    mobileHighlightDataField.value = response.data;
  }
}

async function fetchEasyDataInputJobInvitation() {
  const response = await api.get(
    `easy_data_input_job_invitations/${route.query.easyDataInputJobInvitationToken}`,
  );

  if (response?.data) {
    await acceptEasyDataInputJobInvitation(response.data.token);
  }
}

async function acceptEasyDataInputJobInvitation(token) {
  const response = await api.patch(`easy_data_input_job_invitations/${token}`);

  notificationsStore.addNotification("easyDataInviteAccepted");

  return response;
}
</script>
