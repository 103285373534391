<template>
  <div class="flex items-center space-x-2">
    <DataVisibilityButton :visibility="visibility" class="w-full flex">
      <template v-slot:button>
        <button
          @click="select"
          type="button"
          class="group p-1.5 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-2 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span class="min-w-0 flex-1 flex items-center space-x-2">
            <span class="block flex-shrink-0">
              <span
                :class="
                  visibility === 'safezone' ? 'bg-yellow-500' : 'bg-indigo-600'
                "
                class="inline-flex items-center justify-center h-6 w-6 rounded-full"
              >
                <LinkIcon class="h-4 w-4 text-white" />
              </span>
            </span>
            <span class="block min-w-0 flex-1">
              <span class="block text-sm font-medium text-gray-900 truncate"
                >Link to {{ layout.fieldContent?.name }}</span
              >
            </span>
          </span>
        </button>
      </template>
    </DataVisibilityButton>
    <template v-if="selected">
      <button
        @click="cancel"
        type="button"
        class="inline-flex items-center p-1.5 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <XMarkIcon class="h-4 w-4" />
      </button>

      <DataVisibilityButton :visibility="visibility" class="inline-flex">
        <template v-slot:button>
          <button
            @click="confirm"
            :disabled="originatingData"
            type="button"
            :class="
              visibility === 'safezone'
                ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600'
                : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
            "
            class="flex-shrink-0 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            <PulseLoader
              v-if="originatingData"
              :loading="true"
              size="3px"
              color="#f3f4f6"
            />
            <CheckIcon v-else class="h-4 w-4" />
          </button>
        </template>
      </DataVisibilityButton>
    </template>
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { LinkIcon } from "@heroicons/vue/24/outline";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";

const props = defineProps(["layout"]);
const emit = defineEmits(["linking", "perform-link"]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { originatingData } = storeToRefs(changeGroupStore);

const selected = ref(false);

const visibility = computed(() => {
  if (props.layout.fieldContentType === "PropertyRight") {
    return "public";
  } else {
    return "safezone";
  }
});

function select() {
  selected.value = true;
  emit("linking", props.layout);
}
function cancel() {
  selected.value = false;
  emit("linking", false);
}
function confirm() {
  emit("perform-link");
}
</script>
