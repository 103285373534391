<template>
  <div class="h-full flex flex-col">
    <!-- GROUPING SELECTABLE HEADER -->
    <VMenu
      v-if="isGrouped && internalName === 'Land Coverings (above-grade)'"
      :disabled="editingMode || !groupingDataField"
      theme="diagram-popup"
      class="flex h-2 rounded-t-lg"
    >
      <div
        @click.stop="landCoveringGroupingClickHandler"
        class="w-full"
        :class="landCoveringSelectionBackgroundStylingFor(groupingDataField)"
      />
      <template #popper>
        <DataFieldInfoPopup :data-field="groupingDataField" />
      </template>
    </VMenu>
    <!-- ABOVE-GRADE LAND COVERING SELECTABLE HEADER -->
    <VMenu
      theme="diagram-popup"
      :disabled="editingMode"
      v-if="internalName === 'Land Coverings (above-grade)'"
      class="flex flex-grow items-start justify-start px-1"
    >
      <div @click.stop="landCoveringClickHandler" class="w-full">
        <div class="w-full flex justify-between min-h-[0.5rem]">
          <!-- IMPRECISE FLOOR AREAS -->
          <div v-if="landCovering.hasImpreciseFloorAreas">
            <button @click="upsertSpaceUsageBuilder">
              <ExclamationTriangleIcon
                v-tooltip="'Building contains unlocated leases'"
                class="h-4 w-4 rounded-sm text-orange-400"
              />
            </button>
          </div>
          <div v-else class="h-2" />
          <!-- PROPERTY ENHANCEMENTS -->
          <ul class="flex space-x-1">
            <VMenu
              theme="diagram-popup"
              :disabled="editingMode"
              v-for="enhancement in enhancementsFor(landCoveringDataField)"
              :key="enhancement.localId"
              :data-test="`${
                internalName === 'Land Coverings (above-grade)'
                  ? 'above'
                  : 'below'
              }-land-covering-${landCovering.id}-enhancement-${
                enhancement.fieldContentId
              }`"
              class="h-3 w-3"
            >
              <SparklesIcon
                @click.stop="propertyEnhancementClickHandler(enhancement)"
                v-observe-visibility="{
                  callback: (isVisible, entry) =>
                    propertyEnhancementTimelineSelectionHandler(
                      isVisible,
                      entry,
                      enhancement,
                    ),
                  once: true,
                }"
                :class="`h-3 w-3 rounded-sm ${propertyEnhancementColorStyle(
                  enhancement,
                  propertyDiagramSelected,
                )}`"
              />
              <template #popper>
                <DataFieldInfoPopup :data-field="enhancement" />
              </template>
            </VMenu>
            <InfiniteLoading
              v-if="unitRightsPagy?.pagy?.next && unitRightsPagy.fetched"
              @infinite="
                async ($state) =>
                  propertyDiagramStore.loadPaginatedRecords({
                    state: $state,
                    landCoveringId: landCovering.id,
                    fieldName: 'LandCoveringUnitRight',
                  })
              "
            >
              <template #complete>...</template>
            </InfiniteLoading>
          </ul>
        </div>
      </div>
      <template #popper>
        <DataFieldInfoPopup
          :data-field="landCoveringDataField"
          :custom-content="{ landCovering, height }"
        />
      </template>
    </VMenu>
    <!-- VERTICAL LEVELS -->
    <div
      v-if="landCoveringLevels.length > 0"
      :id="`${gradeLevel}-land-covering-${landCovering.id}-diagram-content-container`"
      class="h-full flex flex-col"
    >
      <InfiniteLoading
        v-if="levelsPagy?.pagy?.next && levelsPagy.fetched"
        @infinite="
          async ($state) =>
            propertyDiagramStore.loadPaginatedRecords({
              state: $state,
              landCoveringId: landCovering.id,
              fieldName: 'LandCoveringLevel',
            })
        "
      >
        <template #complete>...</template>
      </InfiniteLoading>
      <InfiniteLoading
        v-if="verticalOpeningsPagy?.pagy?.next && verticalOpeningsPagy.fetched"
        @infinite="
          async ($state) =>
            propertyDiagramStore.loadPaginatedRecords({
              state: $state,
              landCoveringId: landCovering.id,
              fieldName: 'VerticalOpening',
            })
        "
      >
        <template #complete>...</template>
      </InfiniteLoading>
      <InfiniteLoading
        v-if="
          verticalGroupingsPagy?.pagy?.next && verticalGroupingsPagy.fetched
        "
        @infinite="
          async ($state) =>
            propertyDiagramStore.loadPaginatedRecords({
              state: $state,
              landCoveringId: landCovering.id,
              fieldName: 'VerticalGrouping',
            })
        "
      >
        <template #complete>...</template>
      </InfiniteLoading>
      <InfiniteLoading
        v-if="
          horizontalOrderingsPagy?.pagy?.next && horizontalOrderingsPagy.fetched
        "
        @infinite="
          async ($state) =>
            propertyDiagramStore.loadPaginatedRecords({
              state: $state,
              landCoveringId: landCovering.id,
              fieldName: 'HorizontalVisualOrdering',
            })
        "
      >
        <template #complete>...</template>
      </InfiniteLoading>
      <div
        v-for="dataField in levelsWithViewMetadata"
        :key="`${
          internalName === 'Land Coverings (above-grade)' ? 'above' : 'below'
        }-land-covering-${landCovering.id}-level-${dataField.localId}`"
        class="flex flex-shrink-0"
        :class="dataField.groundLevel ? 'border-b-2 border-gray-600' : ''"
        :style="`height: ${dataField.heightPercentage};`"
      >
        <!-- VERTICAL GROUPING -->
        <VMenu
          theme="diagram-popup"
          :disabled="editingMode || !dataField.verticalGrouping"
          class="h-full flex pointer-events-auto w-2"
          :class="
            dataField.verticalGrouping
              ? landCoveringSelectionBackgroundStylingFor(
                  dataField.verticalGrouping,
                )
              : ''
          "
          :data-test="`${
            internalName === 'Land Coverings (above-grade)' ? 'above' : 'below'
          }-land-covering-${landCovering.id}-level-grouping-${
            dataField.fieldContent.verticalLevel
          }`"
          style="z-index: 1"
        >
          <div
            @click.stop="
              verticalGroupingClickHandler(dataField.verticalGrouping)
            "
            class="w-full h-full"
          />
          <template #popper>
            <DataFieldInfoPopup :data-field="dataField.verticalGrouping" />
          </template>
        </VMenu>

        <!-- LAND COVERING LEVEL -->
        <VMenu
          theme="diagram-popup"
          :disabled="editingMode"
          :class="
            landCoveringSelectionBackgroundStylingFor(dataField) ||
            `text-gray-500 font-medium bg-slate-100 hover:bg-slate-200 ${
              innerContentShouldDisplay ? '' : 'border border-gray-300'
            }`
          "
          class="h-full flex pointer-events-auto w-6 self-center"
          style="z-index: 1"
          :data-test="`${
            internalName === 'Land Coverings (above-grade)' ? 'above' : 'below'
          }-land-covering-${landCovering.id}-level-${
            dataField.fieldContent.verticalLevel
          }`"
        >
          <div
            @click.exact.stop="landCoveringLevelClickHandler(dataField)"
            @click.shift.exact.stop="
              landCoveringLevelClickHandler(dataField, true)
            "
            class="w-full h-full"
          >
            <div
              v-if="innerContentShouldDisplay"
              class="p-0.5 h-full w-full flex flex-col border border-gray-300"
            >
              <!-- PROPERTY ENHANCEMENTS -->
              <ul class="flex flex-wrap">
                <VMenu
                  theme="diagram-popup"
                  :disabled="editingMode"
                  v-for="enhancement in enhancementsFor(dataField)"
                  :key="enhancement.localId"
                  :data-test="`${
                    internalName === 'Land Coverings (above-grade)'
                      ? 'above'
                      : 'below'
                  }-land-covering-${landCovering.id}-level-${
                    dataField.fieldContent.verticalLevel
                  }-enhancement-${enhancement.fieldContentId}`"
                  class="h-3 w-3"
                >
                  <SparklesIcon
                    @click.stop="propertyEnhancementClickHandler(enhancement)"
                    v-observe-visibility="{
                      callback: (isVisible, entry) =>
                        propertyEnhancementTimelineSelectionHandler(
                          isVisible,
                          entry,
                          enhancement,
                        ),
                      once: true,
                    }"
                    :class="`h-3 w-3 ${propertyEnhancementColorStyle(
                      enhancement,
                      propertyDiagramSelected,
                    )}`"
                  />
                  <template #popper>
                    <DataFieldInfoPopup :data-field="enhancement" />
                  </template>
                </VMenu>
              </ul>
              <div
                class="h-full flex justify-center items-center self-center text-center uppercase tracking-wide"
                style="font-size: 8px"
              >
                {{ dataField.fieldContent.verticalLevel }}
              </div>
            </div>
          </div>

          <template #popper>
            <DataFieldInfoPopup :data-field="dataField" />
          </template>
        </VMenu>

        <!-- HORIZONTALLY REORDERABLE FLOOR AREAS -->
        <div
          v-if="
            landCoveringLevelGridRenderable &&
            actionLandCoveringLevelMatchFor(dataField)
          "
          :id="`${gradeLevel}-land-covering-${landCovering.id}-level-${dataField.fieldContentId}-floor-area-container`"
          class="w-full h-full relative"
        >
          <div
            @click.stop
            v-tooltip="floorArea.tooltip"
            v-for="floorArea in dataField.floorAreas"
            :key="`land-covering-${landCovering.id}-level-${dataField.localId}-floor-area-${floorArea.dataField.fieldContentId}-muuri`"
            class="muuri-item block absolute h-full border-l-2 border-b-2 border-white"
            :id="`${floorArea.dataField.fieldContentId}-${floorArea.order}`"
            :style="`width: calc(${floorArea.widthPercentage} - 1px);`"
          >
            <div
              name="floor-area-unit"
              :class="`flex w-full h-full ${dynamicFloorAreaColor(
                floorArea.dataField,
                propertyDiagramSelected,
              )}`"
            />
          </div>
        </div>

        <!-- STATIC HORIZONTAL FLOOR AREAS -->
        <div
          v-else
          @click.stop="landCoveringClickHandlerViaLandCoveringLevel(dataField)"
          class="flex-grow grid"
          :style="`grid-template-columns: ${dataField.gridWidths};`"
        >
          <div
            v-if="maxLevelWidth"
            name="left-alignment-buffer"
            :data-test="`${
              internalName === 'Land Coverings (above-grade)'
                ? 'above'
                : 'below'
            }-land-covering-${landCovering.id}-level-${
              dataField.fieldContent.verticalLevel
            }-left-buffer`"
          />
          <VMenu
            theme="diagram-popup"
            :disabled="editingMode"
            v-for="floorArea in dataField.floorAreas"
            :key="`land-covering-${landCovering.id}-level-${dataField.localId}-floor-area-${floorArea.dataField.fieldContentId}`"
            :class="
              landCoveringSelectionBackgroundStylingFor(floorArea.dataField)
                ? 'flex w-full'
                : `flex w-full border-l-2 ${verticalOpeningStylingFor(
                    dataField,
                    floorArea.dataField,
                  )} ${borderStylingFor(floorArea.dataField)}`
            "
          >
            <div
              @click.stop="
                leveledFloorClickHandler(dataField, floorArea.dataField)
              "
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  leveledFloorAreaTimelineSelectionHandler(
                    isVisible,
                    entry,
                    dataField,
                    floorArea.dataField,
                  ),
                once: true,
              }"
              :data-test="`${
                internalName === 'Land Coverings (above-grade)'
                  ? 'above'
                  : 'below'
              }-land-covering-${landCovering.id}-level-${
                dataField.fieldContent.verticalLevel
              }-floor-area-${floorArea.dataField.fieldContentId}`"
              name="land-covering-level-floor-area"
              class="w-full"
            >
              <LeveledFloorAreaBlock
                :floor-area="floorArea"
                :inner-content-should-display="innerContentShouldDisplay"
                :vertically-open="
                  floorAreaVerticallyOpenFor(dataField, floorArea.dataField)
                "
                :floor-area-layouts="floorAreaLayoutsFor(floorArea.dataField)"
                :enhancements="enhancementsFor(floorArea.dataField)"
                :floor-area-background-styling="
                  landCoveringSelectionBackgroundStylingFor(floorArea.dataField)
                    ? landCoveringSelectionBackgroundStylingFor(
                        floorArea.dataField,
                      )
                    : dynamicFloorAreaColor(
                        floorArea.dataField,
                        propertyDiagramSelected,
                      )
                "
                :internal-property-right-background-styling="
                  floorArea.internalPropertyRight
                    ? landCoveringSelectionBackgroundStylingFor(
                        floorArea.internalPropertyRight,
                      )
                    : null
                "
                :test-key="`${
                  internalName === 'Land Coverings (above-grade)'
                    ? 'above'
                    : 'below'
                }-land-covering-${landCovering.id}-level-${
                  dataField.fieldContent.verticalLevel
                }-floor-area-${floorArea.dataField.fieldContentId}`"
                @internal-property-right-click="
                  internalPropertyRightClickHandler
                "
                @property-enhancement-click="propertyEnhancementClickHandler"
                @floor-area-layout-click="floorAreaLayoutClickHandler"
              />
            </div>
            <template #popper>
              <DataFieldInfoPopup
                :data-field="floorArea.dataField"
                :custom-content="floorArea"
              />
            </template>
          </VMenu>
          <InfiniteLoading
            v-if="
              diagramContentPagy?.['FloorArea']?.[
                `landCoveringLevel${dataField.fieldContentId}`
              ]?.pagy?.next
            "
            @infinite="
              async ($state) =>
                propertyDiagramStore.loadPaginatedRecords({
                  state: $state,
                  landCoveringLevelId: dataField.fieldContentId,
                  fieldName: 'FloorArea',
                })
            "
          >
            <template #complete>...</template>
          </InfiniteLoading>
          <InfiniteLoading
            v-if="
              verticalOrderingsPagy?.pagy?.next && verticalOrderingsPagy.fetched
            "
            @infinite="
              async ($state) =>
                propertyDiagramStore.loadPaginatedRecords({
                  state: $state,
                  landCoveringId: landCovering.id,
                  fieldName: 'VerticalVisualOrdering',
                })
            "
          >
            <template #complete>...</template>
          </InfiniteLoading>
        </div>
      </div>
    </div>

    <!-- VERTICALLY REORDERABLE FLOOR AREAS  -->
    <div
      v-else-if="floorlessGridRenderable"
      :id="`${gradeLevel}-land-covering-${landCovering.id}-floorless-floor-areas`"
      class="w-full h-full relative divide-y-2 divide-white"
    >
      <div
        v-for="(dataField, index) in floorAreasWithViewMetadata"
        :key="`${dataField.fieldContent.id}-${dataField.order}`"
        :id="`${dataField.fieldContent.id}-${dataField.order}`"
        class="muuri-item absolute w-full"
        :style="`height: ${dataField.heightPercentage};`"
      >
        <NoLevelFloorAreaBlock
          :key="`land-covering-floorless-floor-area-${index}-muuri`"
          :floor-area-data-field="dataField"
          :floor-area-layouts="floorAreaLayoutsFor(dataField)"
          :enhancements="enhancementsFor(dataField)"
          :color-style="
            dynamicFloorAreaColor(dataField, propertyDiagramSelected)
          "
          :internal-property-right-background-styling="
            dataField.internalPropertyRight
              ? landCoveringSelectionBackgroundStylingFor(
                  dataField.internalPropertyRight,
                )
              : null
          "
          :clickable="floorAreasClickable"
          :reordering="true"
          @clicked="floorlessClickHandler"
          @refetch="setup"
          @internal-property-right-click="internalPropertyRightClickHandler"
          @property-enhancement-click="propertyEnhancementClickHandler"
          @floor-area-layout-click="floorAreaLayoutClickHandler"
        />
      </div>
    </div>

    <!-- STATIC VERTICAL FLOOR AREAS -->
    <VMenu
      v-else
      theme="diagram-popup"
      :disabled="editingMode || floorAreasWithViewMetadata.length > 0"
      :class="
        floorAreaSelectable
          ? 'w-full h-full flex flex-col'
          : 'w-full h-full flex flex-col divide-y-2 divide-white'
      "
    >
      <div @click.stop="landCoveringClickHandler" class="w-full h-full">
        <VMenu
          theme="diagram-popup"
          :disabled="editingMode"
          v-for="(dataField, index) in floorAreasWithViewMetadata"
          :key="`land-covering-floorless-floor-area-${index}`"
          :test-key="`${
            internalName === 'Land Coverings (above-grade)' ? 'above' : 'below'
          }-land-covering-floorless-floor-area-${index}`"
          class="w-full"
          :style="`height: ${dataField.heightPercentage};`"
        >
          <NoLevelFloorAreaBlock
            :floor-area-data-field="dataField"
            :floor-area-layouts="floorAreaLayoutsFor(dataField)"
            :enhancements="enhancementsFor(dataField)"
            :color-style="
              landCoveringSelectionBackgroundStylingFor(dataField)
                ? landCoveringSelectionBackgroundStylingFor(dataField)
                : dynamicFloorAreaColor(dataField, propertyDiagramSelected)
            "
            :internal-property-right-background-styling="
              dataField.internalPropertyRight
                ? landCoveringSelectionBackgroundStylingFor(
                    dataField.internalPropertyRight,
                  )
                : null
            "
            :clickable="true"
            :reordering="false"
            @clicked="floorlessClickHandler"
            @refetch="setup"
            @internal-property-right-click="internalPropertyRightClickHandler"
            @property-enhancement-click="propertyEnhancementClickHandler"
            @floor-area-layout-click="floorAreaLayoutClickHandler"
          />
          <template #popper>
            <DataFieldInfoPopup :data-field="dataField" />
          </template>
        </VMenu>
        <InfiniteLoading
          v-if="
            diagramContentPagy?.['FloorArea']?.[
              `landCovering${landCovering.id}`
            ]?.pagy?.next
          "
          @infinite="
            async ($state) =>
              propertyDiagramStore.loadPaginatedRecords({
                state: $state,
                landCoveringId: landCovering.id,
                fieldName: 'FloorArea',
              })
          "
        >
          <template #complete>...</template>
        </InfiniteLoading>
      </div>
      <template #popper>
        <DataFieldInfoPopup :data-field="landCoveringDataField" />
      </template>
    </VMenu>

    <!-- BELOW-GRADE LAND COVERING SELECTABLE FOOTER -->
    <VMenu
      theme="diagram-popup"
      :disabled="editingMode"
      v-if="internalName === 'Land Coverings (below-grade)'"
      class="flex flex-grow items-start justify-start px-1"
    >
      <div
        v-if="internalName === 'Land Coverings (below-grade)'"
        @click.stop="landCoveringClickHandler"
        class="w-full"
      >
        <div class="w-full flex min-h-[0.5rem]">
          <div class="h-2" />
        </div>
      </div>
      <template #popper>
        <DataFieldInfoPopup :data-field="landCoveringDataField" />
      </template>
    </VMenu>
  </div>
</template>

<script setup>
import Muuri from "muuri";
import { usePropertyDiagramContentSelectionStore } from "@/stores/propertyDiagramContentSelection";
import getFieldChildrenFromCollection from "@/assets/getFieldChildrenFromCollection";
import api from "@/router/api";
import _ from "lodash";
import percentile from "percentile";
import {
  slabToSlabLevels as slabLevels,
  totalSlabToSlabHeight as totalSlabHeight,
} from "@/assets/landCoveringLevelHeight";
import { onMounted, watch, nextTick, computed, ref } from "vue";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { usePropertyDiagramActionsStore } from "@/stores/propertyDiagramActions";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { storeToRefs } from "pinia";
import { currencyAmount } from "@/assets/numberHelpers";
import selfSelected from "@/components/property-diagram/diagramBlockSelfSelected";
import { inPlaceRent } from "@/components/analyze/calculations/inPlaceRent";
import { floorAreaColorStyle } from "@/components/property-diagram/floorAreaColorStyles";
import {
  expirationColorStyleFor,
  expirationNumberFor,
  inPlaceRentColorStyleFor,
  groupingNumberFor,
} from "@/components/property-diagram/tenancyDiagramLayerColorStyles";
import propertyEnhancementColorStyle from "@/components/property-diagram/propertyEnhancementColorStyles";
import NoLevelFloorAreaBlock from "@/components/property-diagram/NoLevelFloorAreaBlock.vue";
import LeveledFloorAreaBlock from "@/components/property-diagram/LeveledFloorAreaBlock.vue";
import DataFieldInfoPopup from "@/components/crowdsourcing/DataFieldInfoPopup.vue";
import { SparklesIcon, ExclamationTriangleIcon } from "@heroicons/vue/20/solid";

const props = defineProps([
  "internalName",
  "propertyId",
  "landCoveringDataField",
]);
const propertyDiagramStore = usePropertyDiagramStore();
const {
  diagramAllVisibleFields,
  diagramContentPagy,
  legendLayer,
  spaceUsageDataFields,
  innerContentDisplayable,
  propertyDiagramEditAction,
  propertyDiagramAdding,
  propertyDiagramAddedTypes,
  propertyDiagramSelected,
  propertyDiagramLastAdded,
  propertyEnhancements,
  selectedTimelineEvent,
  maxBelowGradeHeight,
  editingMode,
} = storeToRefs(propertyDiagramStore);
const diagramContentSelectionStore = usePropertyDiagramContentSelectionStore();
const propertyDiagramActionsStore = usePropertyDiagramActionsStore();
const {
  editActionModifier,
  editActionType,
  editActionTempValue,
  resetModifier,
  saveModifier,
} = storeToRefs(propertyDiagramActionsStore);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { availabilityGroups, spaces } = storeToRefs(spaceUsageBuilderStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout, workspaceResized, windowHeight, windowWidth } =
  storeToRefs(layoutStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId } = storeToRefs(changeGroupStore);
const timeTravelStore = useTimeTravelStore();
const { timeTravelTo } = storeToRefs(timeTravelStore);
const analyzePanelStore = useAnalyzePanelStore();
const { hoveringTableRowContent } = storeToRefs(analyzePanelStore);

const levelsPagy = computed(() =>
  propertyDiagramStore.diagramContentFor({
    landCoveringId: props.landCoveringDataField.fieldContentId,
    fieldName: "LandCoveringLevel",
  }),
);
const unitRightsPagy = computed(() =>
  propertyDiagramStore.diagramContentFor({
    landCoveringId: props.landCoveringDataField.fieldContentId,
    fieldName: "LandCoveringUnitRight",
  }),
);
const verticalOpeningsPagy = computed(() =>
  propertyDiagramStore.diagramContentFor({
    landCoveringId: props.landCoveringDataField.fieldContentId,
    fieldName: "VerticalOpening",
  }),
);
const verticalGroupingsPagy = computed(() =>
  propertyDiagramStore.diagramContentFor({
    landCoveringId: props.landCoveringDataField.fieldContentId,
    fieldName: "VerticalGrouping",
  }),
);
const verticalOrderingsPagy = computed(() =>
  propertyDiagramStore.diagramContentFor({
    landCoveringId: props.landCoveringDataField.fieldContentId,
    fieldName: "VerticalVisualOrdering",
  }),
);
const horizontalOrderingsPagy = computed(() =>
  propertyDiagramStore.diagramContentFor({
    landCoveringId: props.landCoveringDataField.fieldContentId,
    fieldName: "HorizontalVisualOrdering",
  }),
);

const diagramContentContainer = ref(null);
const scaledInnerContentHeightPx = ref(null);
const floorlessGridRenderable = ref(false);
const floorlessGrid = ref(null);
const landCoveringLevelGridRenderable = ref(false);
const landCoveringLevelGrid = ref(null);

const gradeLevel = computed(() => {
  return props.internalName === "Land Coverings (above-grade)"
    ? "above-grade"
    : "below-grade";
});
const verticalReorderAction = computed(() => {
  return editActionType.value === "verticalReorder";
});
const horizontalReorderAction = computed(() => {
  return editActionType.value === "horizontalReorder";
});
const actionLandCoveringMatch = computed(() => {
  return (
    _.get(propertyDiagramEditAction.value, "landCovering.id", null) ===
    landCovering.value.id
  );
});
const actionLevelMatch = computed(() => {
  return _.includes(
    landCoveringLevelIds.value,
    _.get(
      propertyDiagramEditAction.value,
      "landCoveringLevel.fieldContentId",
      null,
    ),
  );
});
const draggableAction = computed(() => {
  return _.some([verticalReorderAction.value, horizontalReorderAction.value]);
});
const floorAreasClickable = computed(() => {
  return !draggableAction.value;
});
const floorAreaSelectable = computed(() => {
  return (
    propertyDiagramAdding.value.additionType === "property feature" ||
    _.includes(
      ["batch edit", "select renewal and expansion"],
      propertyDiagramAdding.value.additionSubType,
    ) ||
    _.includes(
      ["addCondo", "addCoop", "addLayout", "relocateCondoCoop"],
      editActionType.value,
    )
  );
});
const height = computed(() => {
  const actualHeight = _.get(landCovering.value, "height");

  if (_.get(actualHeight, "meteringStatus") === "unlicensed") {
    return _.get(landCovering.value, "defaultHeight", "");
  } else {
    return actualHeight || "";
  }
});
const innerContentShouldDisplay = computed(() => {
  return scaledInnerContentHeightPx.value >= 25;
});
const landCovering = computed(() =>
  _.get(props.landCoveringDataField, "fieldContent", null),
);
const groupingIds = computed(() =>
  _.get(landCovering.value, "groupingIds", []),
);
const isGrouped = computed(() => {
  return _.size(groupingIds.value) > 0;
});
const groupingDataFields = computed(() => {
  return _.filter(diagramAllVisibleFields.value, function (dataField) {
    return dataField.fieldContentType === "LandCoveringGrouping";
  });
});
const groupingDataField = computed(() => {
  return _.find(groupingDataFields.value, function (dataField) {
    return dataField.fieldContentId === _.head(groupingIds.value);
  });
});
const floorAreas = computed(() => {
  const leveled = scopedFieldsFor("FloorArea", "joiningContent");
  const noLevel = scopedFieldsFor("FloorArea", "decoratingContent");

  return _.unionBy(leveled, noLevel, "localId");
});
const floorAreaIds = computed(() => {
  return floorAreas.value.map((dataField) => dataField.fieldContentId);
});
const landCoveringLevels = computed(() => {
  const rawLevels = scopedFieldsFor("LandCoveringLevel");

  if (props.internalName === "Land Coverings (above-grade)") {
    return rawLevels.filter((df) => df.fieldContent.verticalLevel >= 0);
  } else {
    return rawLevels.filter((df) => df.fieldContent.verticalLevel < 0);
  }
});
const landCoveringLevelIds = computed(() => {
  return landCoveringLevels.value.map((dataField) => dataField.fieldContentId);
});
const spaceUsages = computed(() => {
  if (spaceUsageDataFields.value) {
    return spaceUsageDataFields.value.filter(
      (df) =>
        df.fieldContent?.spaceType === "FloorArea" &&
        _.includes(floorAreaIds.value, df.fieldContent?.spaceId),
    );
  } else {
    return [];
  }
});
const floorAreaLayouts = computed(() => {
  if (landCovering.value) {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return (
        dataField.fieldContentType === "FloorAreaLayout" &&
        dataField.decoratingContentType === "FloorArea" &&
        _.includes(floorAreaIds.value, dataField.decoratingContentId)
      );
    });
  } else {
    return [];
  }
});
const verticalOpenings = computed(() => {
  if (landCovering.value) {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return (
        dataField.fieldContentType === "FloorAreaDownwardVerticalOpening" &&
        dataField.decoratingContentType === "LandCoveringLevel"
      );
    });
  } else {
    return [];
  }
});
const verticalGroupings = computed(() => {
  return scopedFieldsFor("LandCoveringDiagramVerticalGrouping");
});
const internalPropertyRights = computed(() => {
  const floorAreaPropertyRightIds = _.flatMap(
    floorAreas.value,
    function (dataField) {
      return _.get(dataField, "fieldContent.propertyRightIds", []);
    },
  );

  if (landCovering.value) {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return (
        dataField.fieldContentType === "PropertyRight" &&
        dataField.joiningContentType === "Property" &&
        dataField.joiningContentId === props.propertyId &&
        _.includes(floorAreaPropertyRightIds, dataField.fieldContentId)
      );
    });
  } else {
    return [];
  }
});
const scopedOrderingFields = computed(() => {
  if (landCovering.value) {
    return diagramAllVisibleFields.value.filter((dataField) => {
      const matchingDecoratingLandCovering =
        dataField.decoratingContentType === "LandCovering" &&
        dataField.decoratingContentId === landCovering.value.id;
      const matchingJoiningLandCovering =
        dataField.joiningContentType === "LandCovering" &&
        dataField.joiningContentId === landCovering.value.id;
      const matchingLevel =
        dataField.joiningContentType === "LandCoveringLevel" &&
        _.includes(landCoveringLevelIds.value, dataField.joiningContentId);
      return (
        dataField.fieldContentType === "PropertyDiagramVisualOrdering" &&
        (matchingJoiningLandCovering ||
          matchingDecoratingLandCovering ||
          matchingLevel)
      );
    });
  } else {
    return [];
  }
});
const verticalOrderings = computed(() => {
  return scopedOrderingFields.value.filter(
    (dataField) => dataField.fieldContent.direction === "vertical",
  );
});
const horizontalOrderings = computed(() => {
  return scopedOrderingFields.value.filter(
    (dataField) => dataField.fieldContent.direction === "horizontal",
  );
});
const orderedVerticalGroupings = computed(() => {
  const withMetadata = verticalGroupings.value.map((df) => {
    const verticallyGroupedLevelIds = _.get(
      df,
      "fieldContent.groupedContentIds",
      [],
    );
    const areas = verticallyGroupedLevelIds.map((id) => {
      return _.find(levelAreas.value, { id });
    });
    const minLevel = _.minBy(areas, "verticalLevel")?.verticalLevel;

    return _.merge({}, df, { minLevel });
  });

  return _.sortBy(withMetadata, ["minLevel"]);
});
const orderedLandCoveringLevels = computed(() => {
  return _.reverse(
    _.sortBy(landCoveringLevels.value, [
      function (df) {
        return _.get(df, "fieldContent.verticalLevel", null);
      },
    ]),
  );
});
const hasBelowGroundLevels = computed(() => {
  return orderedLandCoveringLevels.value.some((df) => {
    return _.get(df, "fieldContent.verticalLevel", 0) < 0;
  });
});
const landCoveringLevelsWithAlignmentBuffers = computed(() => {
  const bottomFirst = _.sortBy(landCoveringLevels.value, [
    function (df) {
      return _.get(df, "fieldContent.verticalLevel", null);
    },
  ]);
  let belowLevel, belowLevelBuffer;
  let levels = [];

  if (maxLevelWidth.value) {
    bottomFirst.forEach((levelDataField) => {
      if (belowLevelBuffer) {
        belowLevelBuffer = belowRelativeLeftAlignmentBufferFor(
          levelDataField,
          belowLevel,
          belowLevelBuffer,
        );
      } else {
        belowLevelBuffer = maxRelativeLeftAlignmentBufferFor(levelDataField);
      }

      belowLevel = levelDataField;

      levels.push({
        verticalLevel: _.get(
          levelDataField,
          "fieldContent.verticalLevel",
          null,
        ),
        leftBuffer: belowLevelBuffer,
      });
    });
  }

  return levels;
});
const levelAreas = computed(() => {
  return landCoveringLevels.value.map((df) => {
    return {
      id: df.fieldContentId,
      verticalLevel: _.get(df, "fieldContent.verticalLevel", 0),
      area: landCoveringLevelStandardizedAreaFor(
        _.get(df, "fieldContent.floorAreaIds", []),
      ),
    };
  });
});
const maxLevelWidth = computed(() => {
  const floorStandardizedAreas = floorAreas.value.map((df) => {
    return standardizedAreaFor(df);
  });

  if (
    _.every(floorStandardizedAreas) &&
    _.compact(levelAreas.value).length > 0
  ) {
    return _.maxBy(levelAreas.value, "area")?.area;
  } else {
    return null;
  }
});
const slabToSlabLevels = computed(() => {
  return slabLevels(landCoveringLevels.value);
});
const totalSlabToSlabHeight = computed(() => {
  return totalSlabHeight(landCoveringLevels.value);
});
const generalizedLevelHeight = computed(() => {
  const gradeBasedHeight =
    gradeLevel.value === "above-grade"
      ? height.value
      : maxBelowGradeHeight.value;
  const totalGeneralizedHeight = gradeBasedHeight - totalSlabToSlabHeight.value;
  const generalizedLevels =
    landCoveringLevels.value.length - slabToSlabLevels.value.length;

  return totalGeneralizedHeight / generalizedLevels;
});
const levelsWithViewMetadata = computed(() => {
  const gradeBasedHeight =
    gradeLevel.value === "above-grade"
      ? height.value
      : maxBelowGradeHeight.value;
  return orderedLandCoveringLevels.value.map((df) => {
    const levelHeight = _.get(df, "fieldContent.height");
    let percentage;

    if (levelHeight && _.isNumber(levelHeight)) {
      percentage = levelHeight / gradeBasedHeight;
    } else {
      percentage = generalizedLevelHeight.value / gradeBasedHeight;
    }

    const formattedPercentage = percentage * 100;
    const verticalGrouping = verticalGroupingFor(df);
    const verticalGroupingName = _.get(
      verticalGrouping,
      "fieldContent.name",
      null,
    );
    const verticalGroupingTooltip = verticalGrouping
      ? verticalGroupingName ||
        `Vertical Grouping ${verticalGrouping.fieldContentId}`
      : "";
    const floorAreas = levelFloorAreasFor(df, verticalGrouping);
    const floorWidths = floorAreas.map((wrapper) => wrapper.width);
    const leftAlignmentBuffer = _.find(
      landCoveringLevelsWithAlignmentBuffers.value,
      {
        verticalLevel: _.get(df, "fieldContent.verticalLevel", null),
      },
    );

    if (leftAlignmentBuffer) {
      floorWidths.unshift(leftAlignmentBuffer.leftBuffer);
    }

    const gridWidths = floorWidths
      .map((width) => `${_.round(width, 1)}%`)
      .join(" ");
    const standardizedArea = landCoveringLevelStandardizedAreaFor(
      _.get(df, "fieldContent.floorAreaIds", []),
    );
    const standardizedAreaLabel =
      landCoveringLevelStandardizedAreaNetOfVerticalOpenings(
        _.get(df, "fieldContent.floorAreaIds", []),
        df,
      );
    const levelHeightLabel =
      levelHeight && _.isNumber(levelHeight)
        ? ` (${levelHeight}' slab-to-slab)`
        : "";

    return _.merge({}, df, {
      gridWidths,
      standardizedArea,
      tooltip: standardizedAreaLabel
        ? `${_.get(
            df,
            "fieldContent.verticalLevel",
            "",
          )}${levelHeightLabel}: ${currencyAmount(standardizedAreaLabel)} SF`
        : `${_.get(df, "fieldContent.verticalLevel", "")}${levelHeightLabel}`,
      floorAreas,
      heightPercentage: `${formattedPercentage}%`,
      verticalGrouping,
      verticalGroupingTooltip,
      groundLevel:
        hasBelowGroundLevels.value &&
        _.get(df, "fieldContent.verticalLevel", null) === 1,
    });
  });
});
const floorAreasWithViewMetadata = computed(() => {
  const count = floorAreas.value.length;
  const percentage = 1 / count;
  const formattedPercentage = percentage * 100;
  const withMetadata = floorAreas.value.map((dataField, index) => {
    const ordering = _.find(verticalOrderings.value, function (df) {
      return (
        _.get(df, "fieldContent.orderableId", null) ===
          dataField.fieldContentId &&
        _.get(df, "fieldContent.orderableType", null) === "FloorArea" &&
        _.get(df, "fieldContent.containingType", null) === "LandCovering"
      );
    });
    const internalPropertyRight = internalPropertyRightFor(dataField);

    let order;

    if (_.isNumber(_.get(ordering, "fieldContent.order", null))) {
      order = _.get(ordering, "fieldContent.order", null);
    } else {
      order = index;
    }

    return _.merge({}, dataField, {
      heightPercentage: `${formattedPercentage}%`,
      order,
      internalPropertyRight,
    });
  });

  return _.sortBy(withMetadata, ["order"]);
});

const inPlaceRentQuartiles = computed(() => {
  const rawInPlaceRents = spaceUsages.value.map((dataField) =>
    inPlaceRent(dataField.fieldContent, timeTravelTo.value),
  );
  const compactedRents = _.compact(rawInPlaceRents);
  const q1 = percentile(25, compactedRents);
  const q2 = percentile(50, compactedRents);
  const q3 = percentile(75, compactedRents);

  return {
    q1,
    q2,
    q3,
  };
});

function dynamicFloorAreaColor(dataField, selectionObject) {
  switch (legendLayer.value.key) {
    case "physical":
      return floorAreaColorStyle(dataField, selectionObject);
    case "expiration": {
      const floorAreaId = dataField.fieldContentId;
      const usages = spaceUsages.value.filter((df) => {
        return df.fieldContent.spaceId === floorAreaId;
      });
      const expirationNumbers = usages.map(expirationNumberFor);
      const actionableNumber = _.max(expirationNumbers);
      const selected = selfSelected(
        _.get(dataField, "fieldContent"),
        propertyDiagramSelected.value,
      );

      return expirationColorStyleFor(
        actionableNumber,
        timeTravelTo.value,
        selected,
      );
    }
    case "inPlaceRent": {
      const floorAreaId = dataField.fieldContentId;
      const usages = spaceUsages.value.filter((df) => {
        return df.fieldContent.spaceId === floorAreaId;
      });

      const stylingObjects = usages.map((df) => {
        const rent = inPlaceRent(df.fieldContent, timeTravelTo.value);
        return {
          groupingNumber: groupingNumberFor(rent, inPlaceRentQuartiles.value),
          rent,
        };
      });
      const actionableObject = _.maxBy(stylingObjects, "rent");
      const actionableNumber = _.isNumber(actionableObject?.groupingNumber)
        ? actionableObject.groupingNumber
        : -1;
      const selected = selfSelected(
        _.get(dataField, "fieldContent"),
        propertyDiagramSelected.value,
      );

      return inPlaceRentColorStyleFor(actionableNumber, selected);
    }
  }
}

function verticalGroupingFor(landCoveringLevelDataField) {
  return _.find(verticalGroupings.value, {
    fieldContentId: _.get(
      landCoveringLevelDataField,
      "fieldContent.verticalGroupingId",
      null,
    ),
  });
}
// TODO: EXTRACT RICH TOOLTIP
function levelFloorAreasFor(levelDataField, verticalGrouping = null) {
  const floorAreaIds = _.get(levelDataField, "fieldContent.floorAreaIds", []);
  const verticallyGroupedLevelIds = _.get(
    verticalGrouping,
    "fieldContent.groupedContentIds",
    [],
  );
  const floorAreaFields = _.compact(
    floorAreaIds.map((floorAreaId) => {
      return _.find(floorAreas.value, {
        fieldContentId: floorAreaId,
      });
    }),
  );
  const compactIds = floorAreaFields.map((field) => field.fieldContentId);

  const withMetadata = floorAreaFields.map((floorAreaDataField, index) => {
    const standardizedArea = standardizedAreaFor(floorAreaDataField);
    const minimumSubdivisionStandardizedArea = standardizedAreaFor(
      floorAreaDataField,
      "minimumSubdivisionStandardizedArea",
    );
    const verticalOpenings = hasVerticalOpenings(floorAreaDataField);
    const formattedPercentage = floorAreaWidth(
      compactIds,
      floorAreaDataField,
      verticallyGroupedLevelIds,
    );
    const useTypes = floorAreaUseTypesFor(floorAreaDataField);
    const name = _.get(floorAreaDataField, "fieldContent.name", null);
    const identifier = name || floorAreaDataField.fieldContentId;
    const title = `${useTypes.join(", ")} (${identifier})`;
    const openingQualifier = verticalOpenings ? "(vertically open)" : "";
    const size = standardizedArea
      ? `| ${currencyAmount(standardizedArea)} SF ${openingQualifier}`
      : "";
    const tooltip = `${title} ${size}`;
    const ordering = _.find(horizontalOrderings.value, function (df) {
      return (
        _.get(df, "fieldContent.orderableId", null) ===
          floorAreaDataField.fieldContentId &&
        _.get(df, "fieldContent.orderableType", null) === "FloorArea" &&
        _.get(df, "decoratingContentId", null) ===
          levelDataField.fieldContentId &&
        _.get(df, "fieldContent.containingType", null) === "LandCoveringLevel"
      );
    });
    const internalPropertyRight = internalPropertyRightFor(floorAreaDataField);

    let order;

    if (_.isNumber(_.get(ordering, "fieldContent.order", null))) {
      order = _.get(ordering, "fieldContent.order", null);
    } else {
      order = index;
    }

    return _.merge(
      {},
      {
        dataField: floorAreaDataField,
        order,
        tooltip,
        standardizedArea,
        minimumSubdivisionStandardizedArea,
        width: formattedPercentage,
        widthPercentage: `${formattedPercentage}%`,
        internalPropertyRight,
      },
    );
  });

  return _.sortBy(withMetadata, ["order"]);
}
function actionLandCoveringLevelMatchFor(landCoveringLevelDataField) {
  return (
    _.get(
      propertyDiagramEditAction.value,
      "landCoveringLevel.fieldContentId",
      null,
    ) === landCoveringLevelDataField.fieldContentId
  );
}
function internalPropertyRightFor(floorAreaDataField) {
  return _.find(internalPropertyRights.value, function (df) {
    return _.includes(
      _.get(df, "fieldContent.floorAreaIds", []),
      floorAreaDataField.fieldContentId,
    );
  });
}
function standardizedAreaFor(
  floorAreaDataField,
  fieldName = "standardizedArea",
) {
  if (floorAreaDataField) {
    const landCoveringCount = _.get(
      floorAreaDataField,
      "fieldContent.landCoveringCount",
      1,
    );
    const levelsCount = landCoveringLevelsCountFor(floorAreaDataField);
    const verticalOpenings = hasVerticalOpenings(floorAreaDataField);
    const rawNumber = _.get(
      floorAreaDataField,
      `fieldContent.${fieldName}`,
      null,
    );
    const number = rawNumber ? _.toNumber(rawNumber) : null;

    if (number) {
      if (verticalOpenings) {
        return _.round(number / landCoveringCount, 1);
      } else {
        return _.round(number / landCoveringCount / levelsCount, 1);
      }
    } else {
      return null;
    }
  } else return null;
}
function levelAreaFor(landCoveringLevelDataField) {
  const verticalGrouping = verticalGroupingFor(landCoveringLevelDataField);
  const verticallyGroupedLevelIds = _.get(
    verticalGrouping,
    "fieldContent.groupedContentIds",
    [],
  );
  const floorAreaIds = _.get(
    landCoveringLevelDataField,
    "fieldContent.floorAreaIds",
    [],
  );

  return verticallyGroupedLevelIds.length > 0
    ? verticallyGroupedLevelAreaFor(verticallyGroupedLevelIds)
    : landCoveringLevelStandardizedAreaFor(floorAreaIds);
}
function horizontalAlignmentFor(landCoveringLevelDataField) {
  const editingThisLevel =
    _.get(
      propertyDiagramEditAction.value,
      "landCoveringLevel.fieldContentId",
      null,
    ) === _.get(landCoveringLevelDataField, "fieldContentId");
  const cachedAlignment = _.get(
    landCoveringLevelDataField,
    "fieldContent.horizontalAlignment",
    "left",
  );

  if (editActionTempValue.value && editingThisLevel) {
    return editActionTempValue.value || cachedAlignment;
  } else {
    return cachedAlignment;
  }
}
function verticallyGroupedLevelAreaFor(verticallyGroupedLevelIds) {
  const areas = verticallyGroupedLevelIds.map((id) => {
    return _.find(levelAreas.value, { id });
  });

  return _.maxBy(areas, "area")?.area;
}
function landCoveringLevelStandardizedAreaFor(levelFloorAreaIds) {
  const standardizedAreas = levelFloorAreaIds.map((floorAreaId) => {
    const floorAreaDataField = _.find(floorAreas.value, {
      fieldContentId: floorAreaId,
    });

    return standardizedAreaFor(floorAreaDataField);
  });
  const isMeasurable = _.every(standardizedAreas, function (val) {
    return _.isNumber(val);
  });

  if (isMeasurable) {
    return _.sum(standardizedAreas);
  } else {
    return null;
  }
}
function landCoveringLevelStandardizedAreaNetOfVerticalOpenings(
  levelFloorAreaIds,
  landCoveringLevelDataField,
) {
  const standardizedAreas = levelFloorAreaIds.map((floorAreaId) => {
    const floorAreaDataField = _.find(floorAreas.value, {
      fieldContentId: floorAreaId,
    });

    if (
      floorAreaVerticallyOpenFor(landCoveringLevelDataField, floorAreaDataField)
    ) {
      return 0;
    } else {
      return standardizedAreaFor(floorAreaDataField);
    }
  });
  const isMeasurable = _.every(standardizedAreas, function (val) {
    return _.isNumber(val);
  });

  if (isMeasurable) {
    return _.sum(standardizedAreas);
  } else {
    return null;
  }
}
function maxRelativeLeftAlignmentBufferFor(landCoveringLevelDataField) {
  if (maxLevelWidth.value) {
    const levelPercentageOfMax =
      levelAreaFor(landCoveringLevelDataField) / maxLevelWidth.value;
    const maxRemainder = 1 - levelPercentageOfMax;
    let percentage;

    switch (horizontalAlignmentFor(landCoveringLevelDataField)) {
      case "center":
        percentage = maxRemainder / 2;

        return _.round(percentage * 100, 1);
      case "right":
        percentage = maxRemainder;

        return _.round(percentage * 100, 1);
      default:
        return 0;
    }
  } else {
    return 0;
  }
}
function renderableLeftBuffer(belowBuffer, reductionBuffer, currentLevelField) {
  const idealLeftBuffer = belowBuffer - reductionBuffer;
  const currentArea = levelAreaFor(currentLevelField);
  const currentLevelPercentageOfMax = currentArea / maxLevelWidth.value;
  const currentWidth = _.round(currentLevelPercentageOfMax * 100, 1);
  let proposedRender = idealLeftBuffer + currentWidth;

  if (proposedRender > 100) {
    const excess = proposedRender - 100;

    return _.max([idealLeftBuffer - excess, 0]);
  } else {
    return idealLeftBuffer;
  }
}
function belowRelativeLeftAlignmentBufferFor(
  landCoveringLevelDataField,
  belowLevel,
  belowBuffer,
) {
  if (maxLevelWidth.value) {
    const currentBelowDelta =
      levelAreaFor(landCoveringLevelDataField) - levelAreaFor(belowLevel);
    let reduction, reductionBuffer, reductionPercentage;

    switch (horizontalAlignmentFor(landCoveringLevelDataField)) {
      case "center":
        reduction = currentBelowDelta / 2;
        reductionPercentage = reduction / maxLevelWidth.value;
        reductionBuffer = _.round(reductionPercentage * 100, 1);

        return renderableLeftBuffer(
          belowBuffer,
          reductionBuffer,
          landCoveringLevelDataField,
        );
      case "right":
        reduction = currentBelowDelta;
        reductionPercentage = reduction / maxLevelWidth.value;
        reductionBuffer = _.round(reductionPercentage * 100, 1);

        return renderableLeftBuffer(
          belowBuffer,
          reductionBuffer,
          landCoveringLevelDataField,
        );
      default:
        return renderableLeftBuffer(belowBuffer, 0, landCoveringLevelDataField);
    }
  } else {
    return 0;
  }
}
function floorAreaVerticallyOpenFor(
  landCoveringLevelDataField,
  floorAreaDataField,
) {
  const openingMatch = _.find(verticalOpenings.value, function (df) {
    return (
      _.get(df, "fieldContent.floorAreaId", null) ===
        floorAreaDataField?.fieldContentId &&
      _.includes(
        _.get(df, "fieldContent.landCoveringLevelIds", []),
        landCoveringLevelDataField?.fieldContentId,
      )
    );
  });

  return openingMatch;
}
function floorAreaUseTypesFor(floorAreaDataField) {
  const useTypes = _.get(floorAreaDataField, "fieldContent.useTypes", []);

  if (useTypes.length === 0) {
    return ["Awaiting uses"];
  } else {
    return useTypes;
  }
}
function landCoveringLevelsCountFor(floorAreaDataField) {
  if (floorAreaDataField) {
    const levels = landCoveringLevels.value.filter((df) => {
      return _.includes(
        _.get(df, "fieldContent.floorAreaIds", []),
        floorAreaDataField.fieldContentId,
      );
    });

    return levels.length;
  } else return 0;
}
function hasVerticalOpenings(floorAreaDataField) {
  return _.some(verticalOpenings.value, function (df) {
    return (
      _.get(df, "fieldContent.floorAreaId", null) ===
      floorAreaDataField.fieldContentId
    );
  });
}
function floorAreaWidth(
  floorAreaIds,
  floorAreaDataField,
  verticallyGroupedLevelIds = [],
) {
  const standardizedArea = standardizedAreaFor(floorAreaDataField);
  const landCoveringLevelStandardizedArea =
    landCoveringLevelStandardizedAreaFor(floorAreaIds);
  let percentage;

  if (maxLevelWidth.value) {
    const smoothedMax = maxLevelWidth.value;
    const smoothedLevelArea =
      verticallyGroupedLevelIds.length > 0
        ? verticallyGroupedLevelAreaFor(verticallyGroupedLevelIds)
        : landCoveringLevelStandardizedArea;
    const smoothedFloorArea = standardizedArea;
    const smoothedLevelPercentageOfMax = smoothedLevelArea / smoothedMax;
    const areaPercentageOfLevel =
      smoothedFloorArea / landCoveringLevelStandardizedArea;

    percentage = areaPercentageOfLevel * smoothedLevelPercentageOfMax;
  } else if (landCoveringLevelStandardizedArea && standardizedArea) {
    percentage = standardizedArea / landCoveringLevelStandardizedArea;
  } else {
    percentage = 1 / floorAreaIds.length;
  }

  return _.round(percentage * 100, 1);
}

function scopedFieldsFor(fieldContentType, lookupType = "decoratingContent") {
  if (landCovering.value) {
    return diagramAllVisibleFields.value.filter((dataField) => {
      return (
        dataField.fieldContentType === fieldContentType &&
        dataField[`${lookupType}Type`] === "LandCovering" &&
        dataField[`${lookupType}Id`] === landCovering.value.id &&
        dataField.decoratingContentType !== "PropertyRight"
      );
    });
  } else {
    return [];
  }
}

watch(workspaceLayout, () => {
  setScaledInnerContentHeightPx();
});
watch(workspaceResized, () => {
  setScaledInnerContentHeightPx();
});
watch(windowWidth, () => {
  setScaledInnerContentHeightPx();
});
watch(windowHeight, () => {
  setScaledInnerContentHeightPx();
});
watch(landCoveringLevels, () => {
  setScaledInnerContentHeightPx();
});
watch(editActionModifier, () => {
  if (resetModifier.value) {
    resetFloorlessGrid();
    resetLandCoveringLevelGrid();
  } else if (saveModifier.value && draggableAction.value) {
    saveFloorOrderings();
  }
});
watch(editActionType, () => {
  resetFloorlessGrid();
  resetLandCoveringLevelGrid();
});
watch(propertyEnhancements, () => {
  if (propertyEnhancements.value.length > 0)
    propertyEnhancementTimelineSelectionHandler();
});

onMounted(() => {
  setup();
});

async function setup() {
  await nextTick();
  diagramContentContainer.value = document.getElementById(
    `${gradeLevel.value}-land-covering-${landCovering.value.id}-diagram-content-container`,
  );
  setScaledInnerContentHeightPx();
  resetFloorlessGrid();
  resetLandCoveringLevelGrid();
  landCoveringTimelineSelectionHandler();
}

function landCoveringTimelineSelectionHandler() {
  if (!selectedTimelineEvent.value) return;
  if (!props.landCoveringDataField) return;

  const timelineContentType =
    selectedTimelineEvent.value.dataField.decoratingContentType;
  const timelineContentId =
    selectedTimelineEvent.value.dataField.decoratingContentId;

  if (
    timelineContentType === "LandCovering" &&
    timelineContentId === props.landCoveringDataField.fieldContentId
  ) {
    landCoveringClickHandler();
  }
}

function propertyEnhancementTimelineSelectionHandler(
  isVisible,
  entry,
  enhancement,
) {
  if (!isVisible) return;
  if (!selectedTimelineEvent.value) return;

  const timelineContentType =
    selectedTimelineEvent.value.dataField.decoratingContentType;
  const timelineContentId =
    selectedTimelineEvent.value.dataField.decoratingContentId;

  if (
    timelineContentType === "PropertyEnhancement" &&
    timelineContentId === enhancement.fieldContentId
  ) {
    propertyEnhancementClickHandler(enhancement);
  }
}

function leveledFloorAreaTimelineSelectionHandler(
  isVisible,
  entry,
  landCoveringLevelDataField,
  floorAreaDataField,
) {
  if (!isVisible) return;
  if (!selectedTimelineEvent.value) return;

  const timelineContentType =
    selectedTimelineEvent.value.dataField.decoratingContentType;
  const timelineContentId =
    selectedTimelineEvent.value.dataField.decoratingContentId;

  if (
    timelineContentType === "FloorArea" &&
    timelineContentId === floorAreaDataField.fieldContentId
  ) {
    leveledFloorClickHandler(landCoveringLevelDataField, floorAreaDataField);
  }
}

function setScaledInnerContentHeightPx() {
  const containerHeightPx = diagramContentContainer.value
    ? diagramContentContainer.value.offsetHeight
    : null;
  const levelCount = landCoveringLevels.value.length;
  const percentage = 1 / levelCount;

  scaledInnerContentHeightPx.value = containerHeightPx
    ? containerHeightPx * percentage * 0.9
    : 0;

  if (innerContentShouldDisplay.value || levelCount === 0) {
    innerContentDisplayable.value = true;
  } else {
    innerContentDisplayable.value = false;
  }
}

async function resetFloorlessGrid() {
  const canRender =
    landCoveringLevels.value.length === 0 && floorAreas.value.length > 0;
  const shouldRender =
    verticalReorderAction.value && actionLandCoveringMatch.value;

  if (canRender && shouldRender) {
    floorlessGridRenderable.value = false;

    if (floorlessGrid.value) {
      floorlessGrid.value.destroy();
      floorlessGrid.value = null;
    }

    await nextTick();

    floorlessGridRenderable.value = true;

    await nextTick();

    const el = document.getElementById(
      `${gradeLevel.value}-land-covering-${landCovering.value.id}-floorless-floor-areas`,
    );

    if (el) {
      floorlessGrid.value = new Muuri(el, {
        dragEnabled: draggableAction.value && actionLandCoveringMatch.value,
        dragAxis: "y",
      });

      if (editActionModifier.value) {
        propertyDiagramActionsStore.clearEditActionModifier();
      }
    }
  } else {
    floorlessGridRenderable.value = false;

    if (floorlessGrid.value) {
      floorlessGrid.value.destroy();
      floorlessGrid.value = null;
    }
  }
}
async function resetLandCoveringLevelGrid() {
  const canRender =
    landCoveringLevels.value.length > 0 && floorAreas.value.length > 0;
  const shouldRender =
    horizontalReorderAction.value &&
    actionLandCoveringMatch.value &&
    actionLevelMatch.value;

  if (canRender && shouldRender) {
    const cachedEditAction = propertyDiagramEditAction.value;
    landCoveringLevelGridRenderable.value = false;

    if (landCoveringLevelGrid.value) {
      landCoveringLevelGrid.value.destroy();
      landCoveringLevelGrid.value = null;
    }

    await nextTick();

    landCoveringLevelGridRenderable.value = true;

    await nextTick();

    const el = document.getElementById(
      `${gradeLevel.value}-land-covering-${landCovering.value.id}-level-${cachedEditAction.landCoveringLevel.fieldContentId}-floor-area-container`,
    );

    if (el) {
      landCoveringLevelGrid.value = new Muuri(el, {
        dragEnabled: draggableAction.value && actionLandCoveringMatch.value,
        dragAxis: "x",
      });

      if (editActionModifier.value) {
        propertyDiagramActionsStore.clearEditActionModifier();
      }
    }
  } else {
    landCoveringLevelGridRenderable.value = false;

    if (landCoveringLevelGrid.value) {
      landCoveringLevelGrid.value.destroy();
      landCoveringLevelGrid.value = null;
    }
  }
}

function saveFloorOrderings() {
  if (floorlessGrid.value) {
    saveVerticalLandCoveringFloorAreas();
  } else if (landCoveringLevelGrid.value) {
    saveHorizontalLandCoveringLevelFloorAreas();
  }
}
function saveHorizontalLandCoveringLevelFloorAreas() {
  const gridItems = landCoveringLevelGrid.value.getItems();
  const orderables = gridItems.map((item, index) => {
    const elementId = _.split(item._element.id, "-");

    return { id: elementId[0], order: index, orderableType: "FloorArea" };
  });
  const landCoveringLevelId = _.get(
    propertyDiagramEditAction.value,
    "landCoveringLevel.fieldContentId",
    null,
  );
  const payload = {
    containingContentId: landCoveringLevelId,
    containingContentType: "LandCoveringLevel",
    orderables,
    orderableType: "FloorArea",
    direction: "horizontal",
    changeGroupId: changeGroupId.value,
  };

  if (landCoveringLevelId) {
    propertyDiagramActionsStore
      .originateData(() =>
        api.post(`property_diagram_visual_orderings`, payload),
      )
      .then(() => {
        propertyDiagramStore.clearEditAction();
      });
  } else {
    console.log("no landCovering level id?");
  }
}
function saveVerticalLandCoveringFloorAreas() {
  const gridItems = floorlessGrid.value.getItems();
  const orderables = gridItems.map((item, index) => {
    const elementId = _.split(item._element.id, "-");

    return { id: elementId[0], order: index, orderableType: "FloorArea" };
  });
  const payload = {
    containingContentId: landCovering.value.id,
    containingContentType: "LandCovering",
    orderables,
    orderableType: "FloorArea",
    direction: "vertical",
    changeGroupId: changeGroupId.value,
  };

  propertyDiagramActionsStore
    .originateData(() => api.post(`property_diagram_visual_orderings`, payload))
    .then(() => {
      propertyDiagramStore.clearEditAction();
    });
}

function floorAreaLayoutsFor(focalDataField) {
  return getFieldChildrenFromCollection(focalDataField, floorAreaLayouts.value);
}
function enhancementsFor(focalDataField) {
  return getFieldChildrenFromCollection(
    focalDataField,
    propertyEnhancements.value,
  );
}

function landCoveringClickHandler() {
  diagramContentSelectionStore.singleClickSelect({
    propertyId: props.propertyId,
    data: _.merge(
      {},
      { dataField: props.landCoveringDataField, recordType: "LandCovering" },
      {
        floorAreaCount: floorAreas.value.length,
        landCoveringLevelCount: landCoveringLevels.value.length,
      },
    ),
  });
}
function landCoveringGroupingClickHandler() {
  diagramContentSelectionStore.singleClickSelect({
    propertyId: props.propertyId,
    data: _.merge(
      {},
      {
        dataField: groupingDataField.value,
        recordType: "LandCoveringGrouping",
      },
    ),
  });
}
function floorlessClickHandler({ dataField, recordType }) {
  diagramContentSelectionStore.singleClickSelect({
    propertyId: props.propertyId,
    data: _.merge(
      {},
      { dataField, recordType },
      {
        landCovering: landCovering.value,
        floorAreaCount: floorAreas.value.length,
        landCoveringLevelCount: landCoveringLevels.value.length,
      },
    ),
  });
}
function landCoveringClickHandlerViaLandCoveringLevel(
  landCoveringLevelDataField,
) {
  if (landCoveringLevelDataField.floorAreas.length === 0) {
    diagramContentSelectionStore.singleClickSelect({
      propertyId: props.propertyId,
      data: _.merge(
        {},
        { dataField: props.landCoveringDataField, recordType: "LandCovering" },
        {
          floorAreaCount: floorAreas.value.length,
          landCoveringLevelCount: landCoveringLevels.value.length,
        },
      ),
    });
  }
}
function internalPropertyRightClickHandler(internalRightDataField) {
  diagramContentSelectionStore.singleClickSelect({
    propertyId: props.propertyId,
    data: _.merge(
      {},
      {
        dataField: internalRightDataField,
        recordType: "PropertyRight",
        landCovering: landCovering.value,
      },
    ),
  });
}
function leveledFloorClickHandler(
  landCoveringLevelDataField,
  floorAreaDataField,
) {
  diagramContentSelectionStore.singleClickSelect({
    propertyId: props.propertyId,
    data: _.merge(
      {},
      {
        dataField: floorAreaDataField,
        landCoveringLevel: landCoveringLevelDataField,
        landCovering: landCovering.value,
        floorAreaCount: floorAreas.value.length,
        landCoveringLevelCount: landCoveringLevels.value.length,
        recordType: "FloorArea",
      },
    ),
  });
}
function verticalGroupingClickHandler(dataField) {
  if (dataField) {
    diagramContentSelectionStore.singleClickSelect({
      propertyId: props.propertyId,
      data: _.merge(
        {},
        {
          dataField: dataField,
          landCovering: landCovering.value,
        },
      ),
    });
  }
}
function propertyEnhancementClickHandler(dataField) {
  if (dataField) {
    diagramContentSelectionStore.singleClickSelect({
      propertyId: props.propertyId,
      data: _.merge(
        {},
        {
          dataField,
          recordType: "PropertyEnhancement",
        },
      ),
    });
  }
}
function floorAreaLayoutClickHandler(dataField) {
  if (dataField) {
    diagramContentSelectionStore.singleClickSelect({
      propertyId: props.propertyId,
      data: _.merge(
        {},
        {
          dataField,
          recordType: "FloorAreaLayout",
        },
      ),
    });
  }
}
function verticalOpeningEligible(landCoveringLevelDataField) {
  const floorAreaId = propertyDiagramEditAction.value.floorArea.fieldContentId;
  const levelIncludesFloor = _.includes(
    landCoveringLevelDataField.fieldContent.floorAreaIds,
    floorAreaId,
  );

  if (levelIncludesFloor) {
    const includedLevels = landCoveringLevels.value.filter((df) => {
      return _.includes(df.fieldContent.floorAreaIds, floorAreaId);
    });
    const minIncludedLevel = _.minBy(includedLevels, function (df) {
      return _.get(df, "fieldContent.verticalLevel", 0);
    });

    if (
      minIncludedLevel.fieldContentId ===
      landCoveringLevelDataField.fieldContentId
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
function landCoveringLevelClickHandler(dataField, shiftClick = false) {
  if (editActionType.value === "addVerticalGrouping") {
    const hasGrouping = dataField.verticalGrouping;

    if (hasGrouping) {
      return;
    } else {
      emitLandCoveringLevelClick(dataField, shiftClick);
    }
  } else if (editActionType.value === "addVerticalOpenings") {
    if (verticalOpeningEligible(dataField)) {
      emitLandCoveringLevelClick(dataField, shiftClick);
    } else {
      return;
    }
  } else {
    emitLandCoveringLevelClick(dataField, shiftClick);
  }
}
function calculateLandCoveringLevelsToAdd(dataField) {
  const shiftClickFromLevel = _.get(
    propertyDiagramLastAdded.value,
    "fieldContent.verticalLevel",
  );
  const shiftClickToLevel = dataField.fieldContent.verticalLevel;

  if (_.isNumber(shiftClickFromLevel) && _.isNumber(shiftClickToLevel)) {
    const minLevel = _.min([shiftClickFromLevel, shiftClickToLevel]);
    const maxLevel = _.max([shiftClickFromLevel, shiftClickToLevel]);

    return landCoveringLevels.value.filter((df) => {
      return _.inRange(df.fieldContent.verticalLevel, minLevel, maxLevel + 1);
    });
  } else {
    return null;
  }
}
function emitLandCoveringLevelClick(dataField, shiftClick = false) {
  diagramContentSelectionStore.singleClickSelect({
    propertyId: props.propertyId,
    data: _.merge(
      {},
      { dataField, recordType: "LandCoveringLevel" },
      {
        landCovering: landCovering.value,
        hasBelowGroundLevels: hasBelowGroundLevels.value,
        shiftClickLevelsToAdd: shiftClick
          ? calculateLandCoveringLevelsToAdd(dataField)
          : null,
      },
    ),
  });
}
function landCoveringSelectionBackgroundStylingFor(dataField) {
  if (dataField) {
    const selectedText = "border-2 text-gray-700 font-semibold border-gray-700";
    const unselectedText = "text-gray-500 font-medium";
    const selectedStyling =
      "border-2 border-dashed border-green-500 text-white bg-green-200 cursor-pointer";
    const selectableStyling =
      "border-2 border-dashed border-indigo-500 text-gray-300 bg-gray-50 hover:bg-indigo-100 hover:text-indigo-300 hover:border-green-500 cursor-pointer";
    const standardizedField = dataField.localId
      ? dataField
      : dataField.coveringDataField;
    const recordType = _.get(
      standardizedField,
      "fieldContent.recordType",
      null,
    );

    if (diagramContentSelectionStore.alreadyAdded(standardizedField)) {
      return `${selectedText} ${selectedStyling}`;
    } else if (
      (propertyDiagramAdding.value.additionType &&
        diagramContentSelectionStore.propertyIsSelected(props.propertyId)) ||
      propertyDiagramAdding.value.additionSubType === "batch edit"
    ) {
      switch (propertyDiagramAdding.value.additionType) {
        case "floor area":
          switch (recordType) {
            case "FloorArea": {
              if (
                propertyDiagramAdding.value.additionSubType ===
                "select renewal and expansion"
              ) {
                return `${unselectedText} ${selectableStyling}`;
              } else {
                return null;
              }
            }
            case "LandCoveringLevel": {
              if (
                _.includes(["addCondo", "addCoop"], editActionType.value) ||
                propertyDiagramAdding.value.additionSubType ===
                  "select renewal and expansion"
              ) {
                return null;
              } else {
                return `${unselectedText} ${selectableStyling}`;
              }
            }
            case "LandCoveringDiagramVerticalGrouping":
            default:
              return null;
          }
        case "property feature":
          switch (recordType) {
            case "LandCoveringLevel":
            case "FloorArea":
              return `${unselectedText} ${selectableStyling}`;
            default:
              return null;
          }
        case null: {
          if (propertyDiagramAdding.value.additionSubType === "batch edit") {
            switch (recordType) {
              case "LandCoveringLevel":
              case "FloorArea": {
                if (
                  _.includes(propertyDiagramAddedTypes.value, recordType) ||
                  propertyDiagramAddedTypes.value.length === 0
                ) {
                  return `${unselectedText} ${selectableStyling}`;
                } else {
                  return null;
                }
              }
              default:
                return null;
            }
          } else {
            return null;
          }
        }
        default:
          return null;
      }
    } else {
      switch (recordType) {
        case "LandCoveringGrouping": {
          return selfSelected(
            standardizedField.fieldContent,
            propertyDiagramSelected.value,
          )
            ? `${getBackgroundColorFor(
                standardizedField,
                groupingDataFields.value,
                "selected",
              )}`
            : `${getBackgroundColorFor(
                standardizedField,
                groupingDataFields.value,
                "unselected",
              )} hover:${getBackgroundColorFor(
                standardizedField,
                groupingDataFields.value,
                "selected",
              )}`;
        }
        case "LandCoveringDiagramVerticalGrouping": {
          return selfSelected(
            standardizedField.fieldContent,
            propertyDiagramSelected.value,
          )
            ? `${selectedText} ${getBackgroundColorFor(
                standardizedField,
                orderedVerticalGroupings.value,
                "selected",
              )}`
            : `${unselectedText} ${getBackgroundColorFor(
                standardizedField,
                orderedVerticalGroupings.value,
                "unselected",
              )} hover:${getBackgroundColorFor(
                standardizedField,
                orderedVerticalGroupings.value,
                "selected",
              )}`;
        }
        case "LandCoveringLevel": {
          if (editActionType.value === "addVerticalGrouping") {
            const hasGrouping = standardizedField.verticalGrouping;

            if (hasGrouping) {
              return null;
            } else {
              return `${unselectedText} ${selectableStyling}`;
            }
          } else if (editActionType.value === "addVerticalOpenings") {
            if (verticalOpeningEligible(standardizedField)) {
              return `${unselectedText} ${selectableStyling}`;
            } else {
              return;
            }
          } else if (editActionType.value === "relocateFloorArea") {
            return `${unselectedText} ${selectableStyling}`;
          } else {
            return selfSelected(
              standardizedField.fieldContent,
              propertyDiagramSelected.value,
            )
              ? `${selectedText} bg-slate-200`
              : null;
          }
        }
        case "FloorArea": {
          if (
            _.includes(
              ["addLayout", "addCondo", "addCoop"],
              editActionType.value,
            )
          ) {
            return `${unselectedText} ${selectableStyling}`;
          } else if (editActionType.value === "relocateCondoCoop") {
            return `${unselectedText} ${selectableStyling}`;
          } else {
            return null;
          }
        }
        case "PropertyRight": {
          switch (standardizedField.fieldContent.type) {
            case "Condominium":
            case "Co-op":
              return selfSelected(
                standardizedField.fieldContent,
                propertyDiagramSelected.value,
              )
                ? `${getBackgroundColorFor(
                    standardizedField,
                    internalPropertyRights.value,
                    "selected",
                  )}`
                : `${getBackgroundColorFor(
                    standardizedField,
                    internalPropertyRights.value,
                    "unselected",
                  )} hover:${getBackgroundColorFor(
                    standardizedField,
                    internalPropertyRights.value,
                    "selected",
                  )}`;
            default:
              return null;
          }
        }
        default:
          return null;
      }
    }
  }
}

function getBackgroundColorFor(
  standardizedField,
  collection,
  selectedStyling = "selected",
) {
  const colorIndex = _.findIndex(collection, {
    fieldContentId: standardizedField.fieldContentId,
  });
  let colors = [];
  switch (standardizedField.fieldContentType) {
    case "LandCoveringGrouping":
      colors = [
        { selected: "bg-cyan-300", unselected: "bg-cyan-200" },
        { selected: "bg-orange-300", unselected: "bg-orange-200" },
        { selected: "bg-yellow-300", unselected: "bg-yellow-200" },
        { selected: "bg-rose-300", unselected: "bg-rose-200" },
      ];
      break;
    case "LandCoveringDiagramVerticalGrouping":
      colors = [
        { selected: "bg-lime-300", unselected: "bg-lime-200" },
        { selected: "bg-teal-300", unselected: "bg-teal-200" },
        { selected: "bg-blue-300", unselected: "bg-blue-200" },
        { selected: "bg-purple-300", unselected: "bg-purple-200" },
        { selected: "bg-fuchsia-300", unselected: "bg-fuchsia-200" },
        { selected: "bg-green-300", unselected: "bg-green-200" },
      ];
      break;
    default:
      // Internal property rights use icons
      colors = [
        { selected: "text-lime-800", unselected: "text-lime-500" },
        { selected: "text-teal-800", unselected: "text-teal-500" },
        { selected: "text-blue-800", unselected: "text-blue-500" },
        { selected: "text-purple-800", unselected: "text-purple-500" },
        { selected: "text-fuchsia-800", unselected: "text-fuchsia-500" },
        { selected: "text-green-800", unselected: "text-green-500" },
      ];
  }
  const modIndex = colorIndex % colors.length;
  const rawColor = colors[modIndex];

  return rawColor[selectedStyling];
}

function availabilityGroupMember(dataField, recordType) {
  if (
    recordType === "FloorArea" &&
    availabilityGroups.value &&
    availabilityGroups.value.length > 0
  ) {
    const matchingGroup = _.find(
      availabilityGroups.value,
      function ({ availabilities }) {
        const areas = _.compact(
          _.map(
            availabilities,
            function ({ existingAvailability, topLevelSpace }) {
              return existingAvailability?.space || topLevelSpace;
            },
          ),
        ).filter(({ fieldContentType }) => fieldContentType === "FloorArea");

        return _.some(areas, function ({ fieldContentId }) {
          return fieldContentId === dataField.fieldContentId;
        });
      },
    );

    return matchingGroup;
  } else {
    return false;
  }
}
function spaceEditorMember(dataField, recordType) {
  if (recordType === "FloorArea" && spaces.value && _.size(spaces.value) > 0) {
    const matchingSpace = _.find(
      spaces.value,
      function ({ dataField: spaceDataField }) {
        const { fieldContentId, fieldContentType } = spaceDataField;
        return (
          fieldContentType === "FloorArea" &&
          fieldContentId === dataField.fieldContentId
        );
      },
    );

    return matchingSpace ? _.merge({}, matchingSpace, {}) : null;
  } else {
    return false;
  }
}
function analyzeTableRowHoveringMember(dataField, recordType) {
  if (recordType === "FloorArea") {
    if (hoveringTableRowContent.value?.recordType === "FloorArea") {
      return dataField.fieldContentId === hoveringTableRowContent.value.id;
    } else if (
      hoveringTableRowContent.value?.decoratingContentType ===
        "SpaceAvailability" &&
      hoveringTableRowContent.value?.fieldContentType === "FloorArea"
    ) {
      return (
        dataField.fieldContentId ===
        hoveringTableRowContent.value.fieldContentId
      );
    } else if (
      hoveringTableRowContent.value?.fieldContentType ===
      "SpaceAvailabilityGroup"
    ) {
      const groupAvailabilities =
        hoveringTableRowContent.value.fieldContent?.availabilities;

      if (_.isArray(groupAvailabilities)) {
        const rawGroupSpaceIds = groupAvailabilities.map(
          ({ topLevelSpace }) => {
            if (topLevelSpace?.fieldContentType === "FloorArea") {
              return topLevelSpace?.fieldContentId;
            } else if (topLevelSpace?.recordType === "FloorArea") {
              return topLevelSpace?.id;
            } else {
              return null;
            }
          },
        );
        const groupSpaceIds = _.compact(rawGroupSpaceIds);

        return _.includes(groupSpaceIds, dataField.fieldContentId);
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function borderStylingFor(floorAreaDataField) {
  const standardizedField = floorAreaDataField.localId
    ? floorAreaDataField
    : floorAreaDataField.coveringDataField;
  const recordType = _.get(standardizedField, "fieldContent.recordType", null);
  const matchingGroup = availabilityGroupMember(floorAreaDataField, recordType);
  const matchingSpace = spaceEditorMember(floorAreaDataField, recordType);
  const matchingAnalyzeHover = analyzeTableRowHoveringMember(
    floorAreaDataField,
    recordType,
  );
  if (matchingGroup || matchingSpace) {
    return `border-r-2 border-t-2 border-b-2 ${
      matchingGroup?.diagramBorderColor || matchingSpace?.diagramBorderColor
    }`;
  } else if (matchingAnalyzeHover) {
    return "border-r-2 border-t-2 border-b-2 border-red-600";
  } else {
    return "border-white";
  }
}
function verticalOpeningStylingFor(
  landCoveringLevelDataField,
  floorAreaDataField,
) {
  return floorAreaVerticallyOpenFor(
    landCoveringLevelDataField,
    floorAreaDataField,
  )
    ? ""
    : "border-b-2";
}
function upsertSpaceUsageBuilder() {
  spaceUsageBuilderStore.upsertSpaceUsageBuilder({
    spaceDataField: props.landCoveringDataField,
  });
}
</script>

<style scoped>
.muuri-item.muuri-item-dragging {
  z-index: 53;
}
.muuri-item.muuri-item-releasing {
  z-index: 52;
}
.muuri-item.muuri-item-hidden {
  z-index: 50;
}
</style>
